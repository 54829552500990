import React, { useState, useEffect } from "react";
import { TableWithPagination, FilterGenerator } from "../components";
import {
  Box,
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { LeadNewheading, LeadNewpath } from "../utilities";
import { ScreensStylesParent } from "./style";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { AddNewUser, AddRole } from "./adduser";
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps } from "../utilities";
import axios from "axios";
import { config } from "../../../config";
import { AlertDialog, UseDebounce } from "../../../components";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import moment from "moment";

const filter = {
  name: "",
};
const initial = {
  module: "",
  role: "",
  company: "",
  property: "",
  personname: "",
  email: "",
  phone: "",
  employeeID: "",
  userID: "",
  isEdit: false,
  id: "",
  error: {
    module: "",
    role: "",
    company: "",
    property: "",
    personname: "",
    email: "",
    phone: "",
    employeeID: "",
  },
};
export const Person = (props) => {
  const { t } = props
  // style
  const classes = ScreensStylesParent();
  // state
  const debounce = UseDebounce()
  const [drawer, setDrawer] = useState(false);
  const [filterData, setFilterData] = useState({ ...filter });
  const [stateData, setStateData] = useState({ ...initial });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [list, setList] = useState({
    list: [],
    count: "",
  });
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [offset, setoffSet] = useState(null);

  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [moduleOption, setmoduleOption] = React.useState([]);

  //   add role validation
  const addRolevalidate = () => {
    let isValid = true;
    let error = stateData.error;
    if (stateData?.module?.length === 0) {
      isValid = false;
      error.module = t("Module is Required");
    }
    if (stateData?.role?.length === 0) {
      isValid = false;
      error.role = t("Role is Required");
    }
    if (stateData?.company?.length === 0) {
      isValid = false;
      error.company = t("Company is Required");
    }
    if (stateData?.property?.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }
    setStateData({ ...stateData, error });

    return isValid;
  };
  // apply filter
  const onApplyFilter = (value) => {
    setFilterData({ ...filterData });
  };
  // dialog close function
  const handleClose = (key) => {
    setDialogOpen(!dialogOpen);
    setHidden(false);
    if (key === "close") {
      setStateData({ ...initial });
    }
  };
  // dialog close function
  const updateState = (key, value) => {
    let error = stateData?.error;
    error[key] = "";
    if (key === "module") {
      setStateData({ ...stateData, role: "", module: value, error });
    } else if (key === "company") {
      let Al = [];
      let ALL = [];
      value.map((val) => {
        if (val.value === 0) {
          ALL.push({ value: 0, label: "Select All" });
          setStateData({ ...stateData, company: ALL, property: [], error });
        } else {
          Al.push(val);
          setStateData({ ...stateData, company: Al, property: [], error });
        }
        return 0;
      });
    } else if (key === "property") {
      let Al = [];
      let ALL = [];
      value.map((val) => {
        if (val.value === 0) {
          ALL.push({ value: 0, label: "Select All" });
          setStateData({ ...stateData, property: ALL, error });
        } else {
          Al.push(val);
          setStateData({ ...stateData, property: Al, error });
        }
        return 0;
      });
    } else {
      setStateData({ ...stateData, [key]: value, error });
    }
  };
  // const addrolePopFunction
  const addrolePopFunction = (key) => {
    if (key === "close") {
      setStateData({
        ...stateData,
        module: "",
        role: "",
        company: "",
        property: "",
      });
    }
    setHidden(!hidden);
  };

  // get userProfiles
  const getUserProfile = (searchText, offset, limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    let data = {
      client: localStorage.getItem("clinetID"),
      search: searchText ?? "",
      offset: offset,
      limit: limit,
    };
    axios
      .post(`${config?.api_url}/iam/get_client_users`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        let result = res?.data?.data?.map((x) => {
          return {
            data: x,
            // eslint-disable-next-line no-useless-concat
            Name:
              `${x?.first_name ?? "-"}` +
              `${x?.last_name?.length > 0 ? x?.last_name : ""}`,
            Role: x?.access?.length,
            EmployeeID: x?.user_no,
            Email: x?.email_id,
            ContactNumber: x?.mobile_no_country_code + x?.mobile_no,
            status: x?.is_verified ? "Active" : "Resend Invite",
            lastlogin: x?.last_logged_in ? moment(x?.last_logged_in).format("YYYY-MM-DD hh:mm A") : "Yet to Login"
          };
        });
        setList({
          list: result ?? [],
          count: res?.data?.totalRecords,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };

  // resend invite
  const resendInvite = (user_id = "") => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Resending Invite...", })
    let payload = { user_id }
    axios.post(
      `${config?.api_url}/iam/invite`, payload,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
      }).then((res) => {
        alert.setSnack({
          ...alert, open: true, msg: t("Invite Resent Successfully!!!"),
          severity: AlertProps.severity.success,
        })
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
      })
      .catch((err) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        alert.setSnack({
          ...alert, open: true, msg: t("Something Went Wrong."),
          severity: AlertProps.severity.error
        })
      })
  }

  const handleIcon = (e, data) => {
    if (e === "view") {

      let final = data?.data?.access?.map((x) => {
        return {
          data: x,
          Module: x?.module?.name,
          Role: x?.roles?.label,
          company: x?.company_ids?.length,
          id: x?.id,
        };
      });
      setDialogOpen(!dialogOpen);
      setStateData({
        ...stateData,
        personname: data?.Name,
        email: data?.Email,
        phone: {
          mobile_code: data?.data?.mobile_no_country_code,
          mobile: data?.data?.mobile_no,
        },
        employeeID: data?.EmployeeID,
        userID: data?.data?.id,
        access_roles: final ?? [],
        isEdit: false,
      });
      getModules(data?.data?.id, final);
    }
    if (e === "resend_button") { resendInvite(data?.data?.user_id) }
  };

  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = value - 1;
    setoffSet(offset);
    getUserProfile(searchText, offset, limit);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getUserProfile(searchText, 0, value);
  };
  // search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => getUserProfile(e, 0, limit), 800)
  };
  // handleIconRole
  const handleIconRole = (e, data) => {
    if (e === "edit") {
      let companies = data?.data?.companies?.map((x) => {
        return {
          value: x?.id,
          label: x?.name,
        };
      });
      let properties = data?.data?.properties?.map((x) => {
        return {
          value: x?.id,
          label: x?.name,
        };
      });
      let module = {
        value: data?.data?.module?.id,
        label: data?.data?.module?.name,
      };
      setStateData({
        ...stateData,
        module: module,
        role: data?.data?.roles,
        company: data?.data?.is_all_company
          ? [{ value: 0, label: "Select All" }]
          : companies,
        property: data?.data?.is_all_property
          ? [{ value: 0, label: "Select All" }]
          : properties,
        isEdit: true,
        id: data?.id,
      });
      setHidden(true);
    }
    if (e === "active") {
      updateFunction("is_active", data?.data?.is_active ? false : true);
    }
    if (e === "delete") {
      deleteTableData(data?.data?.id, data?.data?.is_delete)
    }
  };
  //delete new user
  const deleteTableData = async (data) => {
    const payload = {
      data:{
        id:data,
        is_delete: true
      }
    }
    NetworkCall(
      `${config.api_url}/queries/upsert_permission_access`,
      NetWorkCallMethods.post,
      payload, null, true, false
    )
    .then(rs => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Deleted",
      });
      getUserProfile("", 0, 10);
      handleClose("close");
    }).catch(er => {
      console.log(er)
    })
  }
  const addroleFunction = () => {
    if (addRolevalidate()) {
      const data = {
        user_profile_id: stateData?.userID ?? undefined,
        client_role_id: stateData?.role?.value ?? undefined,
        role_id: stateData?.module?.value ?? undefined,
        company_ids: stateData?.company?.map((x) => x?.value) ?? undefined,
        property_ids: stateData?.property?.map((x) => x?.value) ?? undefined,
        module_id: Number(stateData?.module?.value) ?? undefined,
        active_users_size_update: stateData?.access_roles?.length > 0 ? true : false
      };
      if (stateData?.isEdit) {
        data["id"] = stateData?.id ?? undefined;
      } else {
        data["size_type"] = "active_users_size";
      }
      axios
        .post(
          `${config?.api_url}/iam/${stateData?.isEdit ? "update_client_users" : "create_client_users"
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((res) => {
          getUserProfile(searchText ?? "", offset, limit);
          handleClose("close");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Role Assign ${stateData?.isEdit ? "Updated" : "Created"
              } successfully.`,
          });
        })
        .catch((err) => {
          switch (err?.response?.status) {
            case 406:
              alert.setSnack({
                ...alert, open: true, msg: t(err?.response?.data?.message),
                severity: AlertProps.severity.warning
              })
              break;

            default:
              alert.setSnack({
                ...alert, open: true, msg: t("Something Went Wrong"),
                severity: AlertProps.severity.error
              })
              break;
          }
          handleClose("close");
        });
    }
  };
  const updateFunction = (key, value) => {
    const data = {
      id: stateData?.userID,
    };
    if (key === "is_active") {
      data["is_active"] = value;
    }
    if (key === "is_delete") {
      data["is_delete"] = true;
    }
    axios
      .post(`${config?.api_url}/iam/update_client_users`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        getUserProfile(searchText ?? "", 0, 10);
        handleClose("close");
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Role Assign Updated successfully."),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };

  const getModules = (e, accessedModules) => {

    const filteredArray1 = props?.moduleOption?.filter(obj1 =>
      !accessedModules.some(obj2 => obj1?.value === Number(obj2?.data?.module_id))
    );
    let finalOptions = filteredArray1?.length > 0 ? filteredArray1?.map((item) => {
      return {
        label: item?.label,
        value: item?.value
      }
    }) : []
    setmoduleOption(finalOptions);
  };

  useEffect(() => {
    getUserProfile("", 0, 10);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid container className={classes.content} spacing={2}>
        <Grid item xs={4}>
          <Box>
            {/* search box */}
            <TextField
              className={classes.tabelsearchField}
              placeholder={t("Search by Name, Employee ID")}
              variant={"outlined"}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              size="small"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box display={"flex"} sx={{ float: "right" }}>
            <Box>
              {/* <IconButton
                size="small"
                className={classes.img}
                onClick={() => setDrawer(!drawer)}
              >
                <img src="/images/filter.svg" alt="filter" />
              </IconButton> */}
            </Box>
          </Box>
        </Grid>

        {/* Main tableData */}
        <Grid item xs={12} className={classes.tableMain}>
          <TableWithPagination
            heading={LeadNewheading(t)}
            rows={list?.list ?? []}
            path={LeadNewpath}
            showpagination={true}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            handleIcon={handleIcon}
            page={page}
            limit={limit}
            totalRowsCount={list?.count}
            dataType={[
              { type: ["text"], name: "Name" },
              { type: ["textLink"], name: "Role" },
              { type: ["text"], name: "EmployeeID" },
              { type: ["text"], name: "ContactNumber" },
              { type: ["text"], name: "Email" },
              { type: ["resend_button"], name: "status" },
              { type: ["text"], name: "lastlogin" },
            ]}
            height={"calc(100vh - 350px)"}
            view={true}
            edit={true}
            delete={true} />
        </Grid>
        {/* end Main tableData */}
      </Grid>

      {/* add new user dialog */}

      <AlertDialog
        lg
        open={dialogOpen}
        onClose={() => handleClose("close")}
        isnotTitle
        noBg
        component={
          <Grid container spacing={2}>
            <Grid item xs={hidden ? 7.5 : 12}>
              <div className={hidden ? classes.newUserBoxAfter : classes.newUserBox}>
                <DialogTitle className={classes.header}>
                  <span>{t("User Details")}</span>{" "}
                  <CloseIcon
                    onClick={() => handleClose("close")}
                    style={{ cursor: "pointer" }}
                  />
                </DialogTitle>
                <DialogContent>
                  <AddNewUser
                    updateState={updateState}
                    addRole={stateData}
                    addrolePopFunction={addrolePopFunction}
                    handleIconRole={handleIconRole}
                    moduleOption={moduleOption}
                    t={t}
                  />
                </DialogContent>
              </div>
            </Grid>
            {hidden && (
              <Grid item xs={4.5}>
                <div className={hidden ? classes.newUserBoxAfter : classes.newUserBox}>
                  <DialogTitle className={classes.header}>
                    <span>{stateData?.isEdit ? "Edit" : "Add"} Role</span>{" "}
                  </DialogTitle>
                  <DialogContent>
                    <AddRole
                      updateState={updateState}
                      addrolePopFunction={() => addrolePopFunction("close")}
                      addroleFunction={addroleFunction}
                      addRole={stateData}
                      moduleOption={moduleOption}
                      t={t}
                    />
                  </DialogContent>
                </div>
              </Grid>
            )}
          </Grid>
        }

      />
      {/* <Dialog
        className={classes.addnewdialog}
        open={dialogOpen}
        onClose={() => handleClose("close")}
        maxWidth="800px"
      >

      </Dialog> */}

      {/* filter drawer */}
      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={() => setDrawer(false)}
          components={[
            {
              component: "select",
              value: filterData?.name,
              options: [],
              isMulti: true,
              label: t("Name"),
              state_name: "name",
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </div>
  );
};