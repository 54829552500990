import { Avatar, Box, Button, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormGenerator, SearchFilter, Subheader, TextBox } from "../../components";
// CustomTypography1
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { CustomTypography, WorkerTypeOptions, initial } from "./common";
import { SingleImgUpload } from "./components";
import { useStyles } from "./style";
// concat_string
import { Add } from "@mui/icons-material";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleIcon, CloseIconWithBG, DeleteIcon } from "../../assets";
import { NonAssetIcon } from "../../assets/nonAssetIcon";
import { TemplateAssetIcon } from "../../assets/templateAsset";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getPropertyOptions, getRoutePermissionNew, useWindowDimensions } from "../../utils";
import moment from "moment";
import { CustomLoadOptionsApis } from "../requestView/steps/utils";
import { loadOptionsApis } from "./utils";
const Request = ({
  t
}) => {
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions()
  const selected_company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  const [state, setState] = useState({ ...initial({ t }) });
  const [images, setImages] = React.useState([]);
  const [uuid, setUUID] = React.useState(1);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const { data } = useLocation();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [properties, setProperties] = React.useState([])
  const [slotsData, setSlotsData] = React.useState([])
  const [searchText, setSearchText] = React.useState("")
  const [options, set_options] = useState({
    work_order_status: [],
    urgent_type: [],
    payment_mode: [],
  });
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const [productList, setProductList] = React.useState([])
  const [offset, setOffset] = React.useState(0);
  const [product, setProduct] = React.useState({
    list: [],
    selectedProduct: ""
  })
  const [requestAgainst, setRequestAgainst] = React.useState({
    isUnit: false,
    isProduct: false
  })
  const [selectedSlot, setSelectedSlot] = React.useState("")
  const [defaultValue, setDefaultValue] = React.useState({
    project: "",
    contract: "",
    contact: ""
  })
  const [defaultContact, setDefaultContact] = React.useState({
    first_name: null,
    PrimaryContact: {
      mobile: null
    },
    alternativeContact: null,
    error: {
      first_name: "",
      PrimaryContact: "",
      alternativeContact: "",
    }
  })
  const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]

  // enum get
  const getEnum = async () => {
    const result = await enumSelect([enum_types.work_order_status, enum_types.urgent_type, enum_types.payment_mode]);
    set_options({
      work_order_status: result?.work_order_status,
      urgent_type: result?.urgent_type,
      payment_mode: result?.payment_mode,
    });
  };
  const getSlotMaster = (offset = 0, limit = 10, search = "") => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search
    }
    NetworkCall(
      `${config.api_url}/time_slot_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (data?.main?.isEdit) getDetails(response?.data?.data);
        const result = response?.data?.data?.map((x, i) => {
          return {
            ...x,
            time: TimeIn24HrFormat[i]
          }
        })
        setSlotsData(result)
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const manualResponseUnit = (array) => {
    const details = array?.unit?.map((i) => {
      return {
        label: i?.label,
        value: i?.value
      }
    })
    return details
  };
  const getDefaultProject = (property_id = "", unit_id = "") => {
    const payload = {
      "property_id": property_id,
      "unit_id": unit_id,
      "agreement_unit_id": ""
    }
    NetworkCall(
      `${config?.api_url}/request/get_agreement_project`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.title?.length > 0 || response?.data?.data?.contract_id?.length > 0) {
          const result = {
            label: `${response?.data?.data?.title} - ${response?.data?.data?.project_no}`,
            value: response?.data?.data?.project_id
          }
          const contract = {
            label: response?.data?.data?.contract_no,
            value: response?.data?.data?.contract_id
          }
          setDefaultValue({ ...defaultValue, project: result, contract: contract })
        }
        // if (data?.main?.isEdit) getDetails(response?.data?.data);
        // const result = response?.data?.data?.map((x, i) => {
        //   return {
        //     ...x,
        //     time: TimeIn24HrFormat[i]
        //   }
        // })
        // setSlotsData(result)
      })
      .catch((error) => {
        setDefaultValue({ project: null, contract: null })
        console.log(error)
      });
  };
  const getDefaultContact = (property_id = "", unit_id = "") => {
    const payload = {
      "property_id": property_id,
      "unit_id": unit_id,
      "agreement_unit_id": ""
    }
    NetworkCall(
      `${config?.api_url}/request/get_agreement_contact`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDefaultContact({
          ...defaultContact,
          first_name: response?.data?.data?.[0]?.first_name ?? null,
          PrimaryContact: response?.data?.data?.[0]?.mobile_no !== null ? {
            mobile: response?.data?.data?.[0]?.mobile_no,
            mobile_code: response?.data?.data?.[0]?.mobile_no_country_code
          } : null,
          alternativeContact: response?.data?.data?.[0]?.mobile_no !== null ? {
            mobile: response?.data?.data?.[0]?.alternative_mobile,
            mobile_code: response?.data?.data?.[0]?.alternative_mobile_country_code
          } : null,
          error: {
            first_name: response?.data?.data?.[0]?.first_name ? "" : t("Contact Name is required"),
            PrimaryContact: response?.data?.data?.[0]?.mobile_no ? "" : t("Contact Number is required"),
          }
        })
      })
      .catch((error) => {
        setDefaultContact(null)
        console.log(error)
      });
  };
  const handleContact = (k, v) => {
    let error = defaultContact?.error
    error[k] = ""
    setDefaultContact({ ...defaultContact, [k]: v, error })
  }
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        let propertiesArray = getPropertyOptions(company)
        setProperties(propertiesArray ?? [])
        getEnum()
        getSlotMaster()
        // if (data?.main?.isEdit) getDetails();
        if (!data?.main?.isEdit) {
          let error = {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            visitorDate: "",
            Description: "",
            workNo: "",
            workId: "",
            status: "",
            location: "",
            alternativeContact: "",
            PrimaryContact: "",
            Property: "",
            problemSince: "",
            contactName: "",
            member: "",
            priority: "",
            vendor_email_id: "",
            reg_worker: "",
            worker_contact_name: "",
            profession: "",
            alternative_company: "",
            worker_company_location: "",
            service_charges: "",
            material_charges: "",
            total_paid: "",
            payment_mode: "",
            paid_to: "",
            payment_date: "",
            invoice_no: "",
            invoice_date: "",
            work_completion: "",
            assigned_date: "",
            visitorTime: ""
          }
          setState({ ...state, error })
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, data?.main?.id]);

  // validation
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    let defaultContactError = defaultContact?.error
    if (defaultContact?.first_name === null) {
      isValid = false;
      defaultContactError.first_name = t("Contact Name is Required");
    }
    if (defaultContact?.PrimaryContact?.mobile === null) {
      isValid = false;
      defaultContactError.PrimaryContact = t("Contact Number is Required");
    }
    // if (state?.alternativeContact?.mobile?.length === 0) {
    //   isValid = false;
    //   error.alternativeContact = "AlterNative Mobile is Required";
    // }
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = t("Unit is Required");
    }
    if (state?.visitorDate?.length === 0) {
      isValid = false;
      error.visitorDate = "Prefered date is Required";
    }
    if (state?.location?.length === 0) {
      isValid = false;
      error.location = t("Location is Required");
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = t("Sub category is Required");
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = t("Problem Title is Required");
    }
    if (state?.problemSince?.length === 0) {
      isValid = false;
      error.problemSince = "Problem Since is Required";
    }

    if (state?.Property?.length === 0) {
      isValid = false;
      error.Property = t("Property is Required");
    }

    if (state?.visitorTime === "") {
      isValid = false;
      error.visitorTime = t("Visit Time is Required");
    }
    if (
      state?.Category?.length === 0 ||
      state?.subCategory?.length === 0 ||
      state?.Unit?.length === 0 ||
      state?.Title?.length === 0 ||
      state?.visitorDate?.length === 0 ||
      state?.location?.length === 0 ||
      // state?.alternativeContact?.mobile?.length === 0 ||
      // state?.PrimaryContact?.mobile?.length === 0 ||
      state?.Property?.length === 0 ||
      state?.problemSince?.length === 0
      // state?.contactName?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }

    setState({ ...state, error });
    setDefaultContact({ ...defaultContact, defaultContactError })

    return isValid;
  };
  const manualResponse = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
          label: `${i?.title} - ${i?.project_no}`,
          value: i?.id
        }
      }),
      count: array?.count
    }
    return details
  }
  //   updateState
  const updateState = (key, value, k1, v1, k2, v2) => {
    let error = state?.error;
    error[key] = "";
    error[k1] = "";
    error[k2] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Property") {
      setState({ ...state, Unit: "", contactName: "", PrimaryContact: "", [key]: value, error });
      setDefaultValue({ project: null, contract: null })
      setDefaultContact({
        ...defaultContact, first_name: null, PrimaryContact: {
          mobile: null
        }, alternativeContact: null
      })
    }
    else if (key === "Unit") {
      setState({ ...state, [key]: value, error });
      getInspectionItems(value?.value)
      getDefaultProject(state?.Property?.value, [value?.value])
      getDefaultContact(state?.Property?.value, [value?.value], key, value)

    } else if (key === "worker_type") {
      setState({
        ...state,
        [key]: value,
        reg_worker: "",
        worker_contact_name: "",
        profession: "",
        worker_contact: { mobile: "", },
        alternative_company: "",
        worker_company_location: "",
        error
      });
    }
    else if (key === "project") {
      setDefaultValue({ ...defaultValue, project: value })
    }
    else if (key === "contract") {
      setDefaultValue({ ...defaultValue, contract: value })
    }
    else if (key === "contactName") {
      setDefaultContact({ ...defaultContact, first_name: null })
      setState({ ...state, [key]: value, [k1]: v1, error });
    }
    else if (key === "PrimaryContact") {
      setDefaultContact({ ...defaultContact, PrimaryContact: null })
      setState({ ...state, [key]: value, [k1]: v1, error });
    }
    else if (key === "alternativeContact") {
      setDefaultContact({ ...defaultContact, alternativeContact: null })
      setState({ ...state, [key]: value, [k1]: v1, error });
    }
    else {
      setState({ ...state, [key]: value, [k1]: v1, error });
    }
  };
  //   handleAssetFile
  const handleAssetFile = (data) => {
    setDeletedAssetURL([...deletedAssetURL, data?.id]);
    setImages(images?.filter((val) => val?.url !== data.url));
  };

  const getInspectionItems = (unit_id, offset = 0, search = "", scroll = false) => {

    const payload = {
      unit_id: unit_id,
      search: search,
      offset: offset,
      limit: 10,
      active: null,
      item_type: ["Inventory", "Product", "Services"]
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    NetworkCall(
      `${config.api_url}/inspection_item_mapping/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      if (scroll) {
        setProductList(productList?.concat(response?.data?.data));
      }
      else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setProductList(response?.data?.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  // request creation
  const saveMaintenanceRequest = () => {
    if (validateContact()) {
      setIsDisableBtn(true)
      const payload = {
        type: 2,
        subject: state?.Title ?? null,
        description: state?.Description ?? null,
        category: state?.Category?.value ?? null,
        sub_category: state?.subCategory?.value ?? null,
        problem_since: state?.problemSince ?? null,
        preferred_time:
          state?.visitorDate ?? null,
        contact_name: defaultContact?.first_name ?? undefined,
        contact_mobile: defaultContact?.PrimaryContact?.mobile ?? undefined,
        contact_alternative_mobile: defaultContact?.alternativeContact?.mobile ?? undefined,
        work_order_ref_no: state?.workNo ? state?.workNo : null,
        work_order_date: state?.workdate ? state?.workdate : null,
        vendor_ref: state?.vendor ? state?.vendor?.value : null,
        work_order_status: state?.workerStatus ? state?.workerStatus?.value : null,
        image_urls: images?.map((x) => {
          return {
            url: x.url,
            "file_meta": {
              "file_name": x?.name ?? null,
              "file_size": x?.size ?? null,
              "file_type": x?.type ?? null
            }
          }
        }),
        units: state?.Unit?.label?.length > 0 ? [state?.Unit?.value] : null,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: state?.Property?.value ?? null,
        companyId: data?.main?.company?.value ?? null,
        mobile_country_code: defaultContact?.PrimaryContact?.mobile_code ?? (state?.PrimaryContact?.mobile_code?.length > 0 ? state?.PrimaryContact?.mobile_code : undefined),
        is_active: state?.status,
        inspection_mapping_id: product?.selectedProduct?.id ?? null,
        location_id: state?.location?.label?.length > 0 ? state?.location?.value : null,
        priority: state?.priority,
        vendor_email_id: state?.vendor_email_id,
        reg_worker_id: state?.worker_type === "registered" ? state?.reg_worker?.value : null,
        worker_contact_name: state?.worker_type === "unregistered" ? state?.worker_contact_name : null,
        profession_id: state?.worker_type === "unregistered" ? state?.profession?.value : null,
        worker_contact_no: state?.worker_type === "unregistered" ? state?.worker_contact?.mobile : null,
        worker_contact_country_code: state?.worker_type === "unregistered" ? state?.worker_contact?.mobile_code : null,
        alternative_company: state?.worker_type === "unregistered" ? state?.alternative_company : null,
        location: state?.worker_type === "unregistered" ? state?.worker_company_location : null,
        service_charges: state?.service_charges ? parseInt(state?.service_charges) : 0,
        material_charges: state?.material_charges ? parseInt(state?.material_charges) : 0,
        total_amount: (state?.service_charges ? parseInt(state?.service_charges) : 0) + (state?.material_charges ? parseInt(state?.material_charges) : 0),
        total_paid: (state?.total_paid ? parseInt(state?.total_paid) : 0),
        currency_id: selected_company?.currency_id,
        payment_mode: state?.payment_mode?.value,
        paid_to: state?.paid_to,
        payment_date: state?.payment_date ?? null,
        invoice_no: state?.invoice_no,
        invoice_date: state?.invoice_date ?? null,
        work_completion: state?.work_completion ?? null,
        assigned_date: state?.assigned_date ?? null,
        slot_id: state?.visitorTime?.value ?? null,
        slot_name: state?.visitorTime?.label ?? null,
        project_id: defaultValue?.project?.value ?? null,
        contract_id: defaultValue?.contract?.value ?? null
      };
      if (data?.main?.isEdit) {
        payload["id"] = data?.main?.id;
        payload["deleteAssets"] = deletedAssetURL;
      }
      NetworkCall(
        `${config.api_url}/request/${data?.main?.isEdit === true ? "update" : "create"
        }`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          history.push(Routes?.maintancerequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${data?.main?.isEdit === true ? "Updated" : "Created"
              } successfully.`,
          });
          setIsDisableBtn(false)
        })
        .catch((error) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
          setIsDisableBtn(false)
        });
    }
  };
  // get details
  const getDetails = (slotOptions = []) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
     id:data?.main?.id
    };
    NetworkCall(
      `${config.api_url}/queries/maintenance_request/info`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data;
        let image = result?.images?.map((x) => {
          return {
            url: x?.url,
            size: x?.file_meta?.file_size ?? "-",
            name: x?.file_meta?.file_name ?? '-',
            type: x?.file_meta?.file_type ?? "-",
            id: x?.id ?? ""
          }
        })
        setImages(image ?? []);
        setState({
          Category: {
            value: result?.table?.[0]?.catagory?.id ?? "",
            label: result?.table?.[0]?.catagory?.type ?? "",
          },
          subCategory: {
            value: result?.table?.[0]?.subcatagory?.id ?? "",
            label: result?.table?.[0]?.subcatagory?.type ?? "",
          },
          Unit: {
            value: result?.table?.[0]?.unit?.[0].unit?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].unit?.name ?? "",
            unit_no: result?.table?.[0]?.unit?.[0].unit?.unit_no
          },
          Title: result?.table?.[0]?.subject ?? "",
          visitorDate: new Date(result?.table?.[0]?.prefferedtime) ?? "",
          Description: result?.table?.[0]?.description ?? "",
          alternativeContact: {
            mobile: result?.table?.[0]?.alternative ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",
          },
          PrimaryContact: {
            mobile: result?.table?.[0]?.mobile ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",

          },
          location: result?.table?.[0]?.location?.id ? {
            value: result?.table?.[0]?.location?.id ?? "",
            label: result?.table?.[0]?.location?.name ?? ""
          } : null,
          Property: {
            value: result?.table?.[0]?.unit?.[0].property?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].property?.name ?? "",
          },
          problemSince: new Date(result?.table?.[0]?.problemsince),
          contactName: result?.table?.[0]?.name ?? "",
          status: result?.table?.[0]?.is_active ?? "",
          workdate: result?.table?.[0]?.work_order_date ? new Date(result?.table?.[0]?.work_order_date) : null,
          vendor: result?.table?.[0]?.vendor_refs ?? "",
          workerStatus: result?.table?.[0]?.work_order_status ? {
            value: result?.table?.[0]?.work_order_status,
            label: result?.table?.[0]?.work_order_status
          } : "",
          workNo: result?.table?.[0]?.work_order_ref_no ?? "",
          priority: result?.table?.[0]?.priority ?? "medium",
          vendor_email_id: result?.table?.[0]?.vendor_email_id ?? "",
          worker_type: result?.table?.[0]?.registered_worker?.value
            ? WorkerTypeOptions({ t })?.[0]?.value
            : WorkerTypeOptions({ t })?.[1]?.value,
          reg_worker: result?.table?.[0]?.registered_worker?.value ? result?.table?.[0]?.registered_worker : "",
          worker_contact_name: result?.table?.[0]?.worker_contact_name ?? "",
          profession: result?.table?.[0]?.profession?.value ? result?.table?.[0]?.profession : "",
          worker_contact: {
            mobile: result?.table?.[0]?.worker_contact_no ?? "",
            mobile_code: result?.table?.[0]?.worker_contact_country_code ?? "+91",
          },
          alternative_company: result?.table?.[0]?.alternative_company ?? "",
          worker_company_location: result?.table?.[0]?.worker_company_location ?? "",
          service_charges: result?.table?.[0]?.service_charges?.toString() ?? "",
          material_charges: result?.table?.[0]?.material_charges?.toString() ?? "",
          total_paid: result?.table?.[0]?.total_paid?.toString() ?? "",
          payment_mode: result?.table?.[0]?.payment_mode
            ? {
              value: result?.table?.[0]?.payment_mode,
              label: result?.table?.[0]?.payment_mode
            }
            : "",
          paid_to: result?.table?.[0]?.paid_to ?? "",
          payment_date: result?.table?.[0]?.payment_date ? new Date(result?.table?.[0]?.payment_date) : null,
          invoice_no: result?.table?.[0]?.invoice_no ?? "",
          invoice_date: result?.table?.[0]?.invoice_date ? new Date(result?.table?.[0]?.invoice_date) : null,
          work_completion: result?.table?.[0]?.work_completion,
          assigned_date: result?.table?.[0]?.assigned_date ? new Date(result?.table?.[0]?.assigned_date) : null,
          slot_id: result?.table?.[0]?.slot_id,
          visitorTime: slotOptions?.filter((x) => { return x?.value === result?.table?.[0]?.slot_id })?.[0] ?? "",
          error: {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            visitorDate: "",
            Description: "",
            workNo: "",
            workId: "",
            status: "",
            location: "",
            alternativeContact: "",
            PrimaryContact: "",
            Property: "",
            problemSince: "",
            contactName: "",
            member: "",
            priority: "",
            vendor_email_id: "",
            reg_worker: "",
            worker_contact_name: "",
            profession: "",
            alternative_company: "",
            worker_company_location: "",
            service_charges: "",
            material_charges: "",
            total_paid: "",
            payment_mode: "",
            paid_to: "",
            payment_date: "",
            invoice_no: "",
            invoice_date: "",
            work_completion: "",
            assigned_date: "",
            visitorTime: ""
          },
        });
        setDefaultContact({
          ...defaultContact,
          first_name: result?.table?.[0]?.name ?? null,
          alternativeContact: result?.table?.[0]?.alternative ? {
            mobile: result?.table?.[0]?.alternative ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",
          } : null,
          PrimaryContact: result?.table?.[0]?.mobile ? {
            mobile: result?.table?.[0]?.mobile ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",
          } : null,
        })
        setDefaultValue({
          project: result?.table?.[0]?.projectByID !== null ? {
            label: `${result?.table?.[0]?.projectByID?.title} - ${result?.table?.[0]?.projectByID?.project_no}`,
            value: result?.table?.[0]?.projectByID?.id
          } : null,
          contract: result?.table?.[0]?.contractByID !== null ? {
            label: result?.table?.[0]?.contractByID?.contract_no,
            value: result?.table?.[0]?.contractByID?.id
          } : null,
        })
        setRequestAgainst({ isUnit: result?.table?.[0]?.inspection_item_mapping === null, isProduct: result?.table?.[0]?.inspection_item_mapping?.name?.length > 0 })
        setProduct({ ...product, selectedProduct: { ...result?.table?.[0]?.inspection_item_mapping, manufaturer_name: result?.table?.[0]?.inspection_item_mapping?.item?.manufacturer?.name, location_name: result?.table?.[0]?.location?.name } })
        getInspectionItems(result?.table?.[0]?.unit?.[0].unit?.id)
        setSelectedSlot(slotOptions?.filter((x) => { return x?.value === result?.table?.[0]?.slot_id })?.[0] ?? "")
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const handleSearch = (value) => {
    setSearchText(value)
    getInspectionItems(state?.Unit?.value, offset, value, false);
  }
  const handleSelectProduct = (value) => {
    setProduct({ ...product, selectedProduct: value })
    updateState("location", value?.location_id !== null ? { value: value?.location_id, label: value?.location_name } : null)
  }
  const fetchMoreData = () => {
    setOffset(offset + 10)
    getInspectionItems(state?.Unit?.value, offset + 10, "", true);
  }
  const handleProduct = (type) => {
    if (type === "add") {
      setProduct({ ...product, bool: false })
      setRequestAgainst({ ...requestAgainst, isProduct: true })
    }
    else {
      setRequestAgainst({ ...requestAgainst, isProduct: false })
      setProduct({ ...product, selectedProduct: "" })
      updateState("location", "")
    }
  }

  // const manualDomesticHelperMasterOption = (array) => {
  //   const details = array?.map(i => i)
  //   return details;
  // };
  const handleChangeSlot = (val) => {
    if (val?.time !== null && moment(state?.visitorDate).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
      const currentTime = moment(new Date())
      let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
      let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
      if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
        setSelectedSlot(val)
      } else {
        setSelectedSlot("")
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please choose visit time greater than current time"),
        });
      }
    }
    else {
      setSelectedSlot(val)
    }
  }
  const render = () => {
    return <>
      <Subheader
        title={`${data?.main?.isEdit ? t("Edit") : t("Create")} ${t("Maintenance Request")}`}
        goBack={() => {
          history.goBack(-1);
        }}
      />
      <Box className={classes.root1}>
        <Grid container spacing={3}>
          {/*  SERVICE DETAILS*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("SERVICE DETAILS")}</CustomTypography>
              <FormGenerator
                t={t}
                components={[
                  {
                    isActive: true,
                    component: "select",
                    label: t("Property"),
                    value: state?.Property,
                    placeholder: t("Property"),
                    onChange: (value) => updateState("Property", value),
                    error: state?.error?.Property,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    options: properties
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Unit"),
                    value: state?.Unit,
                    placeholder: t("Unit"),
                    onChange: (value) => updateState("Unit", value),
                    error: state?.error?.Unit,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/unit/list",
                        {
                          "property_id": state?.Property?.value??[],
                          "company_id": data?.main?.company?.value,
                          "status": [true]
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponseUnit
                      ),
                    key: JSON.stringify(state?.Property),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Category"),
                    value: state?.Category,
                    placeholder: t("Category"),
                    onChange: (value) => updateState("Category", value),
                    error: state?.error?.Category,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "maintenance-master/category_master",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Sub-Category"),
                    value: state?.subCategory,
                    placeholder: t("Sub-Category"),
                    onChange: (value) => updateState("subCategory", value),
                    error: state?.error?.subCategory,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "maintenance-master/sub_category_master",
                        {
                          category_id:state?.Category?.value
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                      ),
                    key: JSON.stringify(state?.Category),
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Title"),
                    value: state?.Title,
                    placeholder: t("Problem Title"),
                    onChange: (e) => updateState("Title", e?.target?.value),
                    error: state?.error?.Title,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 6,
                    },
                    isPaginate: true,
                  },

                  {
                    isActive: true,
                    component: "date",
                    label: t("Problem Since"),
                    value: state?.problemSince,
                    placeholder: t("Problem Since"),
                    onChange: (value) => updateState("problemSince", value),
                    error: state?.error?.problemSince,
                    maxDate: new Date(),
                    isRequired: true,
                    isNot: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    // height: "40%"
                  },

                  {
                    isActive: true,
                    component: "select",
                    label: t("Contract"),
                    value: defaultValue?.contract,
                    placeholder: t("Contract"),
                    onChange: (value) => updateState("contract", value),
                    error: state?.error?.contract,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isClearable: true,
                    loadOptions: (search, array, handleLoading) => loadOptionsApis(
                      "contract/get_all_contract",
                      {
                        company_id: data?.main?.company?.value,
                        contract_type: [3],
                        // account_id:data?.sla_account_id?.value,
                        // start_date:details?.agreement_type === "Sale Agreement" ? timeZoneConverter(details?.lease_start_date , "YYYY-MM-DD") : "",
                        // end_date:details?.agreement_type === "Sale Agreement" ?  timeZoneConverter(details?.lease_end_date , "YYYY-MM-DD") : ""
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      // manualResponse,
                      // { all: true }
                    ),
                    key: JSON.stringify(state?.unit),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Project"),
                    value: defaultValue?.project,
                    isClearable: true,
                    placeholder: t("Project"),
                    onChange: (value) => updateState("project", value),
                    error: state?.error?.project,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 4,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) => CustomLoadOptionsApis(
                      "project/drop_down",
                      {
                        company_id: data?.main?.company?.value
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualResponse,
                      // { all: true }
                    ),
                    key: JSON.stringify(state?.unit),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Asset Location"),
                    value: state?.location,
                    placeholder: t("Select Location"),
                    onChange: (value) => updateState("location", value),
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 4,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_location_master",
                        {},
                        search,
                        array,
                        handleLoading,
                        "list",
                        {}
                      ),
                    error: state?.error?.location,
                    isRequired: true,
                    isReadonly: product?.selectedProduct?.name?.length > 0 && product?.selectedProduct?.location_id !== null ? true : false
                  },
                  {
                    isActive: true,
                    component: "date",
                    label: t("Preferred Visit Date"),
                    value: state?.visitorDate,
                    placeholder: t("Choose Preferred Visit Date"),
                    onChange: (value) => updateState("visitorDate", value, "visitorTime", ""),
                    error: state?.error?.visitorDate,
                    minDate: new Date(),
                    isRequired: true,
                    is_popover: true,
                    border: '1.5px solid #E4E8EE !important',
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 4,
                    },
                  },
                  {
                    isActive: true,
                    component: "timeSlot",
                    label: t("Preferred Visit Time"),
                    value: state?.visitorTime,
                    placeholder: t("Choose Preferred Time"),
                    onChange: (value) => updateState("visitorTime", value),
                    isRequired: true,
                    options: slotsData,
                    handleChange: (value) => handleChangeSlot(value),
                    selectedSlot: selectedSlot,
                    error: state?.error?.visitorTime,
                    border: '1.5px solid #E4E8EE !important',
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3.5,
                    },
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 2.5,
                    },
                    isActive: true,
                    component: "priority",
                    // label: t("Priority"),
                    value: state?.priority,
                    placeholder: t("Priority"),
                    onChange: (value) => updateState("priority", value?.value),
                    error: state?.error?.priority,
                    isRequired: true,
                    options: options?.urgent_type,
                    // menuPlacement:"top"
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 3,
                    },
                    isActive: true,
                    component: "toggle",
                    label: t("Status"),
                    value: state?.status,
                    placeholder: t("Status"),
                    onChange: (value) => updateState("status", value),
                    error: state?.error?.status,
                    isRequired: true,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                  },
                  // {
                  //   isActive: true,
                  //   component: "text",
                  //   label: t("Problem Description"),
                  //   value: state?.Description,
                  //   multiline:true,
                  //   placeholder: t("Problem Description"),
                  //   onChange: (e) =>
                  //     updateState("Description", e?.target?.value),
                  //   error: state?.error?.Description,
                  //   size: {
                  //     xs: 12,
                  //     sm: 12,
                  //     md: 6,
                  //     lg: 6,
                  //   },
                  //   isPaginate: true,
                  // },
                ]}
              />
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextBox
                      label={t("Problem Description")}
                      placeholder={t("Problem Description")}
                      onChange={(e) => updateState("Description", e?.target?.value)}
                      value={state?.Description}
                      multiline={true}
                      rowheight={2.5}
                    />
                  </Grid>
                  {(requestAgainst?.isUnit && state?.Unit?.value) ?
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                      <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                          <NonAssetIcon />
                          <Box style={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.unittitle}>{state?.Unit?.label}</Typography>
                            <Typography className={classes.subTitle}>{state?.Unit?.unit_no}</Typography>
                          </Box>
                        </Stack>
                        <Box style={{ cursor: "pointer" }} onClick={() => setRequestAgainst({ ...requestAgainst, isUnit: false })}><DeleteIcon /></Box>
                      </Box>
                    </Grid>
                    : requestAgainst?.isProduct ?
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                        <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                          <Stack direction="row" spacing={2} alignItems={"center"}>
                            {product?.selectedProduct?.assets?.length > 0 ?
                              <Avatar src={typeof product?.selectedProduct?.assets === "string"?JSON.parse(product?.selectedProduct?.assets)?.[0]?.url:product?.selectedProduct?.assets?.[0]?.url} variant="square" className={classes.avatar} />
                              : <TemplateAssetIcon />}
                            <Box style={{ marginInlineStart: "8px" }}>
                              <Typography className={classes.unittitle}>{product?.selectedProduct?.name}</Typography>
                              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                {product?.selectedProduct?.location_name !== null &&
                                  <>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.location_name ?? "-"}</Typography>
                                  </>
                                }
                                {product?.selectedProduct?.manufaturer_name !== null &&
                                  <>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.manufaturer_name ?? "-"}</Typography>
                                  </>
                                }{product?.selectedProduct?.serial_number !== null &&
                                  <>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.serial_number ?? "-"}</Typography>

                                  </>
                                }
                                {product?.selectedProduct?.model_number !== null &&
                                  <>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.model_number ?? "-"}</Typography>

                                  </>
                                }
                                {product?.selectedProduct?.item_condition !== null &&
                                  <>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.itemCondition} noWrap>{product?.selectedProduct?.item_condition ?? "-"}</Typography>
                                  </>
                                }
                              </Stack>
                            </Box>
                          </Stack>
                          <Box style={{ cursor: "pointer" }} onClick={() => handleProduct("delete")}><DeleteIcon /></Box>
                        </Box>
                      </Grid>
                      :
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                              <Stack direction="row" spacing={2} alignItems={"center"}>
                                <NonAssetIcon />
                                <Box style={{ marginInlineStart: "8px" }}>
                                  <Typography className={classes.unittitle}>{t("Unit Level")}</Typography>
                                  <Typography className={classes.subTitle}>{t("For non-asset item")}</Typography>
                                </Box>
                              </Stack>
                              <Box style={{ cursor: "pointer" }}><Add color="primary" onClick={() => setRequestAgainst({ ...requestAgainst, isUnit: state?.Unit?.value ? true : false })} /></Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                              <Stack direction="row" spacing={2} alignItems={"center"}>
                                <TemplateAssetIcon />
                                <Box style={{ marginInlineStart: "8px" }}>
                                  <Typography className={classes.unittitle}>{t("Asset / item from unit")}</Typography>
                                  <Typography className={classes.subTitle}>{t("Eg: AC, TV, Light etc")}</Typography>
                                </Box>
                              </Stack>
                              <Box style={{ cursor: "pointer" }} onClick={() => setProduct({ ...product, bool: true })}><Add color="primary" /></Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                  }
                </Grid>
              </Box>
              {/* <Box mt={2}> */}
              
            </div>
          </Grid>
          {/* CONTACT*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("CONTACT")}</CustomTypography>
              <FormGenerator
                t={t}
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: t("Contact Name"),
                    value: defaultContact?.first_name,
                    placeholder: t("Contact Name"),
                    onChange: (e) =>
                      handleContact("first_name", e?.target?.value),
                    error: defaultContact?.error?.first_name,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isRequired: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: t("Primary Contact"),
                    value: defaultContact?.PrimaryContact,
                    placeholder: t("Primary Contact"),
                    onChange: (value) => handleContact("PrimaryContact", value),
                    error: defaultContact?.error?.PrimaryContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isRequired: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: t("Alternate Contact Number"),
                    value: defaultContact?.alternativeContact,
                    placeholder: t("Alternate Contact Number"),
                    onChange: (value) =>
                      handleContact("alternativeContact", value),
                    errorMessage: defaultContact?.error?.alternativeContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                ]}
              />
            </div>
          </Grid>
         
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("UPLOAD IMAGES")}</CustomTypography>
              <SingleImgUpload
                setUUID={setUUID}
                uuid={uuid}
                updateImageURL={setImages}
                handleFile={handleAssetFile}
                selectedImageURL={images}
                companyId={1}
                t={t}
              />
            </div>
          </Grid>

        
        </Grid>
      </Box>

      {/* buttom btn */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            className={classes.Cancel}
            onClick={() => {
              history.goBack(-1);
            }}
          >
            {t("Cancel")}
          </Button>
        </Box>
        {((data?.main?.isEdit && permission?.update) || (!Boolean(data?.main?.isEdit) && permission?.create)) &&
          <Box>
            <Button className={classes.next} onClick={saveMaintenanceRequest} disabled={isDisableBtn}>
              {data?.main?.isEdit ? t("Update") : t("Submit")}
            </Button>
          </Box>}
      </Box>
      <Drawer
        anchor={"right"}
        open={product?.bool}
        onClose={() => setProduct({ ...product, bool: false, selectedProduct: "" })}
      >
        <Box width="500px" position={"relative"} >
          <Box display="flex" justifyContent={"space-between"} alignItems={"center"} p={1.5} sx={{ backgroundColor: "#F2F4F7" }}>
            <Typography className={classes.drawerHeader}>{t("Asset / Item from unit")}</Typography>
            <Box onClick={() => setProduct({ ...product, bool: false, selectedProduct: "" })} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
          </Box>
          <Box><Divider /></Box>
          <Box p={2}>
            <SearchFilter
              handleChange={handleSearch}
              value={searchText}
              color={"white"}
              placeholder={t("Search Item")}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
            <Box mt={2} mb={8}>
              <InfiniteScroll
                dataLength={productList?.length ?? 10}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 218}
              >
                {productList?.length > 0 ? productList?.map((val, i) => {
                  return (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center" onClick={() => handleSelectProduct(val)}>
                        {product?.selectedProduct?.id === val?.id ?
                          <Box style={{ cursor: "pointer" }}>
                            <CircleIcon color="#5078E1" /></Box>
                          : <Box style={{ cursor: "pointer" }}><CircleIcon />
                          </Box>}

                        {val?.assets?.length > 0 ?
                          <Avatar className={classes.avatar} src={typeof val?.assets === "string"?JSON.parse(val?.assets)?.[0]?.url:val?.assets?.[0]?.url} />
                          :
                          <Avatar className={classes.avatar}>
                            <img src="/images/Group 98362.svg" alt="" />
                          </Avatar>
                        }
                        <Box>
                          <Typography className={classes.productName} noWrap>{val?.name}</Typography>
                          <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            {val?.location_name !== null &&
                              <>
                                <Typography className={classes.productSubTitle} noWrap>{val?.location_name ?? "-"}</Typography>
                              </>
                            }
                            {val?.manufaturer_name !== null &&
                              <>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.productSubTitle} noWrap>{val?.manufaturer_name ?? "-"}</Typography>
                              </>
                            }{val?.serial_number !== null &&
                              <>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.productSubTitle} noWrap>{val?.serial_number ?? "-"}</Typography>

                              </>
                            }
                            {val?.model_number !== null &&
                              <>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.productSubTitle} noWrap>{val?.model_number ?? "-"}</Typography>

                              </>
                            }
                            {val?.item_condition !== null &&
                              <>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.itemCondition} noWrap>{val?.item_condition ?? "-"}</Typography>
                              </>
                            }
                          </Stack>
                        </Box>
                      </Stack>
                      <Box>
                        {productList?.length - 1 !== i &&
                          <Box mt={1.5} mb={1.5}>
                            <Divider />
                          </Box>
                        }
                      </Box>
                    </>
                  )
                })

                  : <Box display={"flex"} justifyContent={"center"}>
                    <Typography>{t("No Items Found")}</Typography>
                  </Box>}
              </InfiniteScroll>
              {/* <Box height="70px" /> */}

            </Box>
            <Box className={classes.addItembtn}>
              <Button variant="contained" fullWidth onClick={() => handleProduct("add")} disabled={product?.selectedProduct?.length === 0}>{t("Add")}</Button>
            </Box>
          </Box>

        </Box>
      </Drawer>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
export default withNamespaces("maintanceRequest")(Request)
