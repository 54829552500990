import React, { useState, useContext } from "react";
import {
  Subheader,
  PropertyTabs,
  AlertDialog,
  SearchFilter,
  TextBox,
  FormGenerator,
  SelectBox,
} from "../../components";
import { LeadStylesParent } from "./style";
import { Grid, Box, Typography, Button, Drawer, Popover, useTheme, CircularProgress, Stack, Skeleton } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { ProfileCard, AllocatedCard } from "../leadnew/components";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Command, UnitTable, ToDoList, Activity } from "./components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AlertProps,
  NetWorkCallMethods,
  useWindowDimensions,
  LocalStorageKeys,
  accessCheckRender,
  getRoutePermissionNew,
  enumSelect,
  enum_types,
} from "../../utils";
import { Card } from "../leadnew/addmembercard";
import { AlertContext, AuthContext } from "../../contexts";
import { Bold } from "../../utils";
import { NewLoader } from "../../components/newLoader";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { CreateLead } from "../../components/createLead";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
import { SummaryView } from "./components/summaryView";
import { EditLead } from "./components/editLead";
import { External } from "./components/external";
import useMediaQuery from '@mui/material/useMediaQuery';
import { LeadTracker } from "./components/leadTracker/leadTracker";



// custom styles
// start
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontFamily: Bold,
  color: theme.palette.background.reservation,
  marginBottom: '12px',
}));

const initial = {
  job: "",
  isShowcard: false,
  member: {},
  isEdit: false,
  isView: false,
  checkbox: false,
  purpose: "",
  name: "",
  des: "",
  id: "",
  classify_lead: "",
  priority: "",
  error: {
    des: "",
    job: "",
    member: "",
    name: "",
  },
};
// INITIAL STATES OF TRANSFER LEAD & LOST LEAD
const initial1 = {
  job: "",
  remark: "",
  Department: "",
  notes:"",
  error: {
    job: "",
    remark: "",
    notes:"",
  },
};

const LeadView = (props) => {

  const { t } = (props)

  const { state } = useLocation();
  // styles
  const classes = LeadStylesParent();
  //   states
  const [details, setDetails] = useState([]);
  const [is_lead_edit, set_is_lead_edit] = useState(false);
  // histry
  const history = useHistory();
  // size
  const size = useWindowDimensions();
  // Location URL
  const searchURL = useLocation().search
  // theme
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  // states
  const [data, setData] = useState({ ...initial });
  const auth = React.useContext(AuthContext)
  const [datatransfer, setDatatransfer] = useState({ ...initial1 });
  const [viewdialog, setviewDialog] = useState(false);
  const [transferLead, setTransferLead] = useState(false);
  const [lostLead, setlostLead] = useState(false);
  const [searchdata, setSearchdata] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [unitList, setUnitList] = useState({
    data: [],
    count: 0,
  });
  const [toDoList, setToDoList] = useState([]);
  const [toDodialog, setToDodialog] = useState(false);
  const [unitPage, setUnitPage] = useState(1);
  const [unitLimit, setUnitLimit] = useState(10);
  const [todoPage, setTodoPage] = useState(1);
  const [todoLimit, setTodoLimit] = useState(10);
  const [tabValue, setTabValue] = useState(state?.main?.activeTab ?? 1);
  const [permission, setPermission] = React.useState({})
  const [enumValue, setEnumValue] = useState([])
  const [loader, setLoader] = useState(false);
  const [create, setCreate] = React.useState({
    bool: false,
    id: null,
    data: {}
  })
  const [disable, setDisable] = useState(false)
  const [isTeam, setIsTeam] = useState({
    data: false,
    loading: false,
    type: ""
  });
  //const 
  const url_lead_id = new URLSearchParams(searchURL).get("id")
  const leadId = url_lead_id?.length > 0 ? url_lead_id : state?.main?.id

  const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  const [state1, setState1] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [disable1, setDisable1] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);

  const [type, setType] = React.useState("");
  const [third, setThird] = React.useState({
    count: 0,
    data: []
  });
  const [thirdLimit, setThirdLimit] = React.useState(10);
  const [thirdPage, setThirdPage] = React.useState(1);
  const Status = details?.status === "In Progress" ? true : false
  const [team, setTeam] = React.useState(state?.main?.team?.value)
  const [trackData, setTrackData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  // context
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popover' : undefined;

  const alert = useContext(AlertContext);
  const tabTittle = [
    {
      label: t("Summary"),
      value: 1,
      className: data?.tabValue === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: t("Comments"),
      value: 2,
      className: data?.tabValue === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: `${t("Internal")} (${unitList?.count ?? 0})`,
      value: 3,
      className: data?.tabValue === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: `${t("External")} (${third?.count ?? 0})`,
      value: 4,
      className: data?.tabValue === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: t("To-do List"),
      value: 5,
      className: data?.tabValue === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: t("Tracker"),
      value: 7,
      className: data?.tabValue === 7 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: t("Activity"),
      value: 6,
      className: data?.tabValue === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
  ];
  const leadtransfer = [
    {
      title: t("Qualify"),
      image: "/images/convertopertunity.svg",
      is_active: Status,
      permission: permission?.create,
    },
    {
      title: t("Transfer"),
      image: "/images/transferlead.svg",
      is_active: Status,
      permission: permission?.update
    },
    {
      title: t("Close"),
      image: "/images/lostlead.svg",
      is_active: Status,
      permission: permission?.update,
      is_close: true
    },
  ];

  // view data
  const viewLeadDeatils = (initial) => {
    const datas = {
      id: leadId
    }
    if (initial) setLoader(true)
    NetworkCall(
      `${config.api_url}/queries/proxy_lead/view`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        let viewData = res?.data?.data?.proxy_lead?.[0];
        setDetails(viewData);
        setSelectedID(viewData?.member)

        setDatatransfer({
          ...datatransfer,
          remark: viewData?.description
        })
        setData({
          ...data,
          priority: {
            value: viewData?.urgent,
            label: viewData?.urgent
          },
          classify_lead: viewData?.classify_lead_id ? viewData?.classify_lead : ""


        })
        setLoader(false)
        getTrackData()
      })
      .catch((err) => {
        setLoader(false)
      });
  };
  const getTrackData = (offset = 0, limit = 10) => {
    setIsLoading(true)
    const payload = {
      "proxy_lead_id": leadId,
      "offset": offset,
      "limit": limit
    };
    NetworkCall(
      `${config.api_url}/proxy_lead_track/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setIsLoading(false)
        setTrackData(response?.data?.data?.proxy_lead_track)
      })
      .catch((error) => {
        setIsLoading(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: props?.t("Some Thing Went Wrong"),
        });
      });
  }
  // handleChange
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // lead view option clicks function (transfer lead , close lead)
  const leadClick = (index, data, t, e) => {
    if (index === 0) {
      setCreate({
        bool: true,
        data: {
          company: state?.main?.company ?? selectedCompany,
          id: leadId,
          is_convert: true,
          data: data
        }
      })
    }
    if (index === 1) {
      getTeams(e?.currentTarget);
    }
    if (index === 2) {
      setType(t)
      setlostLead(!lostLead);
      setviewDialog(!viewdialog);
    }
  };
  // close transfer dialog
  const closeTransfer = () => {
    setTransferLead(!transferLead);
    setDatatransfer({ ...initial1 });
    setSelectedID(null);
    setUserDetails([]);
  };
  // close lead dialog
  const closeLost = () => {
    setlostLead(!lostLead);
    setDatatransfer({ ...initial1 });
  };
  const handleCreate = (key, value) => {
    setCreate({ [key]: value })
  }
  // add commands
  const addLost = (key) => {
    setDisable1(true)
    let datas = {};
    if (key === "transfer") {
      datas = {
        lead_owner: selectedID?.id,
        note: datatransfer?.notes ?? "",
      };
    }
    if (key === "close") {
      if (type === "Archieved") {
        datas = {
          status: "Archieved",
          archieved_description: datatransfer?.remark ?? "",
          note: datatransfer?.notes ?? "",
        };
      }
      else if (type === "Unqualified") {
        datas = {
          status: "Unqualified",
          remarks: datatransfer?.remark ?? "",
          note: datatransfer?.notes ?? "",
        };
      }
      else {
        datas = {
          status: "Lost Lead",
          remarks: datatransfer?.remark ?? "",
          note: datatransfer?.notes ?? "",
        };
      }

    }
    if (key === "delete") {
      datas = {
        is_active: false,
      };
    }
    const payload = {
      ...datas,
      id: leadId ?? "",
    }
    NetworkCall(
      `${config.api_url}/lead_proxy/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (key === "transfer") {
          closeTransfer();
        }
        if (key === "close") {
          closeLost();
        }
        if (key === "delete") {
          closeLost();
        }
        viewLeadDeatils();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${key === "transfer" ? t("Lead Transfer") : t("Lead closed")
            } ${t("updated Successfully")}`,
        });
        setDisable1(false)
      })
      .catch((error) => {
        setDisable1(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // update state function
  const updateState = (k, v) => {
    let error = data?.error;
    error[k] = "";
    setData({ ...data, [k]: v });
  };
  // update state function
  const updateState1 = (k, v) => {
    let error = datatransfer?.error;
    error[k] = "";
    setDatatransfer({ ...datatransfer, [k]: v });
  };
  const [selectedID, setSelectedID] = useState(null);
  const handleSearchTransfer = (v) => {
    setSearchdata(v);
    getUserProfile(0, v, false, isTeam?.type, team?.value);
  };
  // get user profiles in pop up
  const getUserProfile = (offset, search, is_load, type, teams) => {
    if (!is_load) {
      setLoading1(true);
    }
    NetworkCall(
      `${config.api_url}/lead/transfer_owners`,
      NetWorkCallMethods.post,
      {
        start: offset,
        length: 10,
        search: search ?? "",
        team: type === "against_team" ? teams : undefined,
        module_id: localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
      },
      null,
      true,
      false
    )
      .then((res) => {
        const user = res?.data?.data?.map((x) => {
          return {
            ...x,
            is_active: (details?.lead_source === x?.id) ? true : false
          }
        })
        if (is_load) {
          setUserDetails(userDetails?.concat(user));
        } else {
          setUserDetails(user);
        }
        setLoading1(false)
      })
      .catch((err) => {
        setLoading1(false)
      });
  };
  // infinity scroll transfer lead
  const fetchMoreDataTransfer = () => {
    setOffset(offset + 10);
    getUserProfile(offset + 10, searchdata, true, isTeam?.type, team?.value);
  };
  // selected users
  const selectUser = (x) => {
    setSelectedID(x);
    updateState("member", x?.user_profileByID);
  };
  // cancel transfer lead
  const previous1 = () => {
    setTransferLead(false);
  };
  // cancel close lead
  // const previous2 = () => {
  //   setlostLead(false);
  // };

  const convertOppertunity = (val) => {
    history.push({
      pathname: Routes.createOpportunity,
      state: { lead: val, company: selectedCompany, is_convert: true, proxy_id: leadId, primary: state1, summary_details: details }
    })
  }

  // validate transfer lead
  const validatemain = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is Required");
    }

    setData({ ...data, error });

    return isValid;
  };

  // sumbit transfer lead
  const TransferLead = (key) => {
    if (selectedID?.id?.length > 0) {
      addLost(key);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select One Member"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  // sumbit lost lead
  const LostLeadFunction = (key) => {
    if (validate1()) {
      addLost(key);
    }
  };
  // validate close lead
  const validate1 = () => {
    let isValid = true;
    let error = datatransfer.error;
    if (datatransfer?.notes?.length === 0) {
      isValid = false;
      error.notes = t("Remark is Required");
    }

    setDatatransfer({ ...datatransfer, error });

    return isValid;
  };
  // get units
  const getUnits = (offset, limit, initial) => {
    if (initial) setLoader(true)
    const datas = {
      id: leadId,
      offset,
      limit
    }
    NetworkCall(
      `${config.api_url}/queries/proxy_lead/unit`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        let result = res?.data?.proxy_lead_units?.map((x) => {
          return {
            id: x?.unit?.unit_no,
            unit_no: x?.unit?.unit_no,
            image: x?.unit?.logo,
            name: x?.unit?.name,
            propertyname: x?.unit?.property?.name,
            type: x?.unit?.unit_type_masterByID?.name,
            lead_id: x?.unit?.id
          };
        });
        setUnitList({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count,
        });
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      });
  };
  // add todolist
  const addTodo = () => {
    if (validatemain()) {
      setDisable(true)
      const datas = {
        name: data?.name ?? "",
        description: data?.des ?? "",
        proxy_lead_id: leadId ?? "",
        company_id: selectedCompany?.value ?? null

      }
      NetworkCall(
        `${config.api_url}/queries/proxy_lead/upsert_check_list`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          closeTodo();
          setDisable(false)
          getToDo(0, 10);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Todo Created Successfully")}`,
          });
          setLoader(false)

        })
        .catch((error) => {
          setDisable(false)

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went wrong please try again"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setLoader(false)

        });
    }
  };
  // close todo
  const closeTodo = () => {
    setToDodialog(!toDodialog);
    setData({ ...initial });
  };
  // GET TODO
  const getToDo = (offset, limit) => {
    const datas = {
      id: leadId,
      offset,
      limit
    }
    NetworkCall(
      `${config.api_url}/queries/proxy_lead/check_list`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        let result = res?.data?.proxy_lead_checklist?.map((x) => {
          return {
            id: x?.id,
            Name: x?.name,
            Description: x?.description,
            checkbox: x?.is_done ?? false,
            icon: x?.is_done === false ? "edit" : "",
            status: x?.is_done ?? false,
          };
        });
        setToDoList({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count,
        });
        setLoader(false)

      })
      .catch((err) => {
        setLoader(false)

      });
  };

  // pagination function
  const handlePagination = (value) => {
    setUnitPage(value);
    let offset = (value - 1) * unitLimit;
    getUnits(offset, unitLimit);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setUnitLimit(value);
    setUnitPage(1);
    getUnits(0, value);
  };

  // pagination function
  const toDohandlePagination = (value) => {
    setTodoPage(value);
    let offset = (value - 1) * unitLimit;
    getToDo(offset, unitLimit);
  };
  // pagination limit change function
  const toDohandleChangeLimit = (value) => {
    setTodoLimit(value);
    setTodoPage(1);
    getToDo(0, value);
  };
  // handle icon todo
  const handleIconToDo = (type, value, e) => {
    if (type === "edit") {
      setToDodialog(true);
      setData({
        ...data,
        name: value?.Name,
        des: value?.Description,
        id: value?.id,
        checkbox: value?.checkbox,
        isEdit: true,
      });
    }
    if (type === "checkBox") {
      updateTodo(true, e, value?.id, true);
    }
    if (type === "view") {
      if (!value?.checkbox) {
        setToDodialog(true);
        setData({
          ...data,
          name: value?.Name,
          des: value?.Description,
          id: value?.id,
          isEdit: false,
          isView: true,
          checkbox: value?.checkbox,
        });
      }
    }
  };
  const updateTodo = (is_done, value, id, is_popup) => {
    const updatePayload = {};
    if (is_done === true) {
      updatePayload["is_done"] = value;
    } else {
      updatePayload["name"] = data?.name ?? "";
      updatePayload["description"] = data?.des ?? "";
    }
    const datas = {
      ...updatePayload,
      id: data?.id || id,
    }
    NetworkCall(
      `${config.api_url}/queries/proxy_lead/upsert_check_list`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        if (is_popup !== true) {
          closeTodo();
        }
        getToDo(0, 10);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${t("Todo Update Successfully")}`,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const markTodo = () => {
    updateTodo(true, data?.checkbox ? false : true, data?.id, false);
  };

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.urgent_type
    ]);
    setEnumValue({
      priority: result?.urgent_type,
    });
  };


  const updateLead = (values) => {
    const datas = {
      id: leadId,
      ...values
    }
    NetworkCall(
      `${config.api_url}/lead_proxy/upsert`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: values?.msg ?? `${t("Lead")} ${props?.isEdit?.bool === true ? t("Updated") : t("Created")} ${t("successfully")}.`,
        });
        if (values?.is_reload) {
          viewLeadDeatils();
          return getUnits(0, 10)
        }
        viewLeadDeatils();
        setLoader(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
        setLoader(false)
      });
  }

  // pagination function
  const handlePaginationThird = (value) => {
    setThirdPage(value);
    let offset = (value - 1) * thirdLimit;
    getThirdParty(offset, thirdLimit);
  };
  // pagination limit change function
  const handleChangeLimitThird = (value) => {
    setThirdLimit(value);
    setThirdPage(1);
    getThirdParty(0, value);
  };
  const getThirdParty = (offset, limit) => {

    NetworkCall(
      `${config.api_url}/lead_proxy/get/third_party`,
      NetWorkCallMethods.post,
      {
        proxy_lead_id: leadId,
        offset: offset,
        limit: limit,
      },
      null,
      true,
      false
    )
      .then((res) => {
        setThird({
          data: res?.data?.data?.data?.map((x) => {
            return {
              ...x,
              icon: "viewdelete"
            }
          }),
          count: res?.data?.data?.count
        })
      })
      .catch((err) => {

      });
  };
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        viewLeadDeatils(true);
        getUnits(0, 10, true);
        getToDo(0, 10);
        getEnum()
        getThirdParty(0, 10)
        setTeam(state?.main?.team)
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const style = {
    overflow: "overlay",
    height: size?.height - 202,
  }

  const tabPanelStyle = {
    padding: "24px",
    paddingBottom: "0px",
  }

  const buttonStyle = {
    height: "auto",
    alignSelf: "center !important",
  }

  const handleEdit = () => {
    set_is_lead_edit(true);
  };

  const handleGoBack = () => {
    is_lead_edit
      ? set_is_lead_edit(false)
      : history.goBack(-1)
  }

  const statusType = {
    "Archieved": "Archived",
    "Disqualify": "Disqualify",
    "Unqualified": "Unqualified"
  }

  const statusType1 = {
    "to_an_individual": "To an Individual",
    "against_team": "Against Team"
  }

  const clickType = (x) => {
    handleClose1();
    setTransferLead(true);
    setviewDialog(!viewdialog);
    setIsTeam({
      ...isTeam,
      type: x,
    })
    if (x === "against_team") {
      if (team?.value) getUserProfile(0, "", false, x, team?.value)
    }
    else {
      getUserProfile(0, "", false, x)
    }
  }

  const getTeams = (event) => {
    setIsTeam({
      ...isTeam,
      loading: false,
      data: state?.main?.team?.value,
      type: state?.main?.team?.value
        ? "against_team"
        : "to_an_individual",
    });
    setSelectedID(details?.member)
    setAnchorEl1(event);
  };


  const render = () => {
    return <>
      <Subheader
        title={is_lead_edit ? t("Edit Lead") : t("View Lead")}
        select
        isReadOnly
        value={state?.main?.company ?? selectedCompany}
        goBack={handleGoBack}
        padding={'0px'}
        top={'2px'}
      />
      {
        loader ? <NewLoader /> :
          <div className={classes.root}>
            {is_lead_edit
              ? <EditLead t={t} data={details} set_is_lead_edit={set_is_lead_edit} reload={viewLeadDeatils} />
              : <Grid container spacing={3} sx={{ mt: "-18px !important", ml: "-22px !important" }}>
                <Grid item xs={12} sm={12} md={5} lg={4} className={classes.padding}>
                  {/* LEFT SIDE SECTION */}
                  <div className={classes.detailsParent}>
                    <div
                      style={{
                        textAlign: "-webkit-match-parent",
                        overflow: "auto",
                        height: "100%",
                        // `calc(100vh - 320px)`,
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column"
                      }}
                    >
                      {/*   LEAD DETAILS */}
                      <Box>
                        <CustomTypography>{t("LEAD DETAILS")}</CustomTypography>
                        <ProfileCard t={t} viewDetails={details} />
                        {/*    ALLOCATED DETAILS */}
                        {details?.member?.first_name?.length > 0 && (
                          <div style={{ marginTop: "16px" }}>
                            <CustomTypography>{t("ALLOCATED DETAILS")}</CustomTypography>
                            <AllocatedCard t={t} viewDetails={details} />
                          </div>
                        )}
                      </Box>

                      <Box>
                        {
                          Status && <Box pt={2}>
                            <FormGenerator t={t} components={[
                              {
                                size: {
                                  xs: 12,
                                  sm: 12,
                                  md: 6,
                                  lg: 6,
                                },
                                isActive: true,
                                component: "priority",
                                label: t("Priority Field"),
                                value: data?.priority?.value,
                                placeholder: t("Select Priority"),
                                selectHeight: "40px",
                                onChange: (value) => {
                                  updateState("priority", value)
                                  if (!!value?.value) {
                                    updateLead({
                                      urgent: value?.value,
                                      is_load: false,
                                      msg: 'Priority Updated'

                                    })
                                  }
                                },
                                error: data?.error?.priority,
                                options: enumValue?.priority,
                                is_dot: true,
                                backgroundColor: "#fff",
                                menuPlacement: "top"
                              },
                              {
                                size: {
                                  xs: 12,
                                  sm: 12,
                                  md: 6,
                                  lg: 6,
                                },
                                isActive: true,
                                component: "priority1",
                                label: t("Classify the Lead"),
                                value: data?.classify_lead,
                                placeholder: t("Select Classify the Lead"),
                                selectHeight: "40px",
                                onChange: (value) => {
                                  updateState("classify_lead", value)
                                  if (!!value?.value) {
                                    updateLead({
                                      classify_lead_id: value?.value,
                                      is_load: false,
                                      msg: "Lead Updated Successfully"
                                    })
                                  }

                                },
                                error: data?.error?.classify_lead,
                                backgroundColor: "#fff",
                                loadOptions: (search, array, handleLoading) =>
                                  loadOptionsApis(
                                    "queries/proxy_lead/get_classify",
                                    {},
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                  ),
                                isPaginate: true,
                                menuPlacement: "top"

                              },
                            ]} />
                          </Box>
                        }


                        {<div className={classes.transferParent}>
                          <Grid container spacing={1}>
                            {
                              leadtransfer?.map((x, index) => {
                                return (
                                  <>
                                    {(x?.permission && x?.is_active) && (
                                      <Grid item xs={4} sm={4} md={4} lg={4}>
                                        {
                                          isTeam?.loading && index === 1 ? (
                                            <Box
                                              className={classes.leadtransfersbox}
                                              sx={{ justifyContent: "center" }}
                                            >
                                              <CircularProgress size={24} />
                                            </Box>
                                          ) :
                                            <Box
                                              className={classes.leadtransfersbox}
                                              onClick={(e) => x?.is_close ? handleClick(e) : leadClick(index, details, t, e)}
                                              aria-describedby={id} variant="contained"
                                            >
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img src={x?.image} alt={x?.title} />
                                                <Typography
                                                  className={classes.options}
                                                  style={{ marginLeft: "8px" }}
                                                  noWrap
                                                >
                                                  {x?.title}
                                                </Typography>
                                              </Box>
                                            </Box >
                                        }

                                      </Grid >
                                    )}
                                  </>
                                );
                              })
                            }
                          </Grid >

                        </div >}
                      </Box >
                    </div>
                    {/* lead option (TRANSFER LEAD , LOSE LEAD) */}
                  </div >
                </Grid >
                <Grid item xs={12} sm={12} md={7} lg={8} className={classes.paddingTab}>
                  <PropertyTabs
                    showBtns={tabValue === 1}
                    isEdit={Status ? (tabValue === 1 ? true : false) : false}
                    handleedit={handleEdit}
                    padding={"15px 11px"}
                    value={tabValue}
                    is_cancel
                    isHeigth
                    isAfter
                    t={t}
                    style={tabValue === 1 && style}
                    tabPanelStyle={tabValue === 1 && tabPanelStyle}
                    buttonGroupPadding={"0px"}
                    buttonStyle={tabValue === 1 && buttonStyle}
                    paddingTab={'24px'}
                    backgroundColor={'#fff'}
                    handleChange={handleChange}
                    tabTittle={tabTittle}
                    customTabWidth={isMd ? "450px" : Status ? size.width - 120 : size.width - 70}
                    tab1={<SummaryView t={t} data={details} Status={Status} />}
                    tab2={
                      <Command
                        viewDetails={details}
                        viewLeadDeatils={viewLeadDeatils}
                        permission={permission}
                        t={t}
                        Status={Status}
                      />
                    }
                    tab3={
                      <UnitTable
                        unitList={unitList}
                        page={unitPage}
                        limit={unitLimit}
                        handleChangeLimit={handleChangeLimit}
                        handlePagination={handlePagination}
                        size={size}
                        selectedCompany={selectedCompany?.value}
                        updateLead={updateLead}
                        lead_id={leadId}
                        details={details}
                        t={t}
                        Status={Status}
                      />
                    }
                    tab4={
                      <External
                        unitList={third}
                        page={thirdPage}
                        limit={thirdLimit}
                        handleChangeLimit={handleChangeLimitThird}
                        handlePagination={handlePaginationThird}
                        size={third?.count}
                        selectedCompany={selectedCompany?.value}
                        lead_id={leadId}
                        t={t}
                        getThirdParty={getThirdParty}
                        Status={Status}
                      />
                    }
                    tab5={
                      <ToDoList
                        updateState={updateState}
                        state={data}
                        dialogTittle={`${data?.isEdit
                          ? t("Edit")
                          : `${data?.isView ? t("View") : t("Create")}`
                          } To Do`}
                        btnTitle={`${data?.isEdit
                          ? t("Update")
                          : `${data?.isView ? t("Mark as Complete") : t("Create")}`
                          }`}
                        sumbit={
                          data?.isEdit
                            ? updateTodo
                            : data?.isView
                              ? markTodo
                              : addTodo
                        }
                        closeTodo={closeTodo}
                        toDodialog={toDodialog}
                        toDoList={toDoList}
                        page={todoPage}
                        limit={todoLimit}
                        handleChangeLimit={toDohandleChangeLimit}
                        handlePagination={toDohandlePagination}
                        handleIconToDo={handleIconToDo}
                        details={details}
                        t={t}
                        Status={Status}
                        disable={disable}
                      />
                    }
                    tab7={<LeadTracker t={t} proxy_lead_id={leadId} trackData={trackData} isLoading={isLoading} />}
                    tab6={<Activity t={t} proxy_lead_id={leadId} details={details} selectedCompany={selectedCompany} Status={Status} />}
                  />
                </Grid>
              </Grid >}
          </div >
      }
      {/* transfer lead */}
      <AlertDialog
        open={transferLead}
        onClose={closeTransfer}
        isNormal
        header={t("Transfer Lead")}
        component={
          <>
            <div>
              {
                isTeam?.type === "against_team" &&
                <div style={{ padding: "16px" }}>
                  <SelectBox
                    alignItem="start"
                    isRequired
                    label={t("Team")}
                    placeholder={t("Select Team")}
                    value={team}
                    onChange={(value) => {
                      getUserProfile(0, "", false, isTeam?.type, value?.value)
                      setTeam(value);
                      setSelectedID("")
                    }}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "hierarchy-team/get_all_team",
                        {
                          // company_id: 1,
                          module: localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                      )
                    }
                    isError={datatransfer?.error?.job?.length > 0}
                    errorMessage={datatransfer?.error?.job}
                    isReadOnly={state?.main?.team?.value}
                  />
                </div>
              }

              <div id={"infinity"}>
                {/* search box */}

                <div style={{ padding: "24px 16px 16px 16px" }}>
                  <SearchFilter
                    value={searchdata}
                    handleChange={(value) => handleSearchTransfer(value)}
                    placeholder={t("Search Member Details")}
                  />
                </div>
                <div style={{ padding: "0px 16px 16px" }}>
                  {
                    loading1 ?
                      <Stack spacing={0.5} height={size?.height - 400} overflow={"auto"}>
                        {
                          Array.from(Array(8).keys())?.map(() => {
                            return <Skeleton width={"100%"} height={"150px"} />
                          })
                        }
                      </Stack>
                      :
                      <InfiniteScroll
                        dataLength={userDetails?.length ?? ""}
                        next={fetchMoreDataTransfer}
                        hasMore={true}
                        height={size?.height - 400}
                      >
                        <Card
                          data={userDetails}
                          selectUser={selectUser}
                          select={selectedID}
                          t={t}
                        />
                      </InfiniteScroll>
                  }
                </div>
                {/* button */}
                <Box className={classes.bottombtn}>
                  <Box>
                    <Button className={classes.Cancel} onClick={previous1}>
                      {t("Cancel")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className={classes.next}
                      onClick={() => TransferLead("transfer")}
                      disabled={(!selectedID?.id || disable1)}
                    >
                      {t("Transfer Lead")}
                    </Button>{" "}
                  </Box>
                </Box>
              </div>
            </div>
          </>
        }
      />
      {/* LOST LEAD */}
      <AlertDialog
        open={lostLead}
        onClose={closeLost}
        isNormal
        header={type === "Disqualify" ? t("Disqualify") : type === "Unqualified" ? t("Unqualify") : t("Archived")}
        header_padding={"16px 8px 16px 16px"}
        component={
          <div>
            <div
              style={{
                padding: "16px",
              }}
            >
              <Grid item xs={12}>
                <TextBox
                  isrequired
                  label={t("Remark")}
                  placeholder={t("Type Remarks")}
                  value={datatransfer?.notes ?? ""}
                  multiline
                  padding={"8px"}
                  onChange={(e) => {
                    updateState1("notes", e.target.value);
                  }}
                  rowheight={8}
                // isError={datatransfer?.error?.remark?.length > 0}
                // errorMessage={datatransfer?.error?.remark}
                />
              </Grid>
            </div>
            {/* button */}
            <Box className={classes.bottombtn1}>
              {/* <Box>
                <Button className={classes.Cancel} onClick={previous2}>
                  Cancel
                </Button>
              </Box> */}
              <Box>
                <Button
                  className={classes.nextfull}
                  style={{ width: '100%' }}
                  onClick={() => LostLeadFunction("close")}
                  disabled={!datatransfer?.notes?.length}
                  variant="contained"
                >
                  {type === "Disqualify" ? t("Disqualify") : type === "Unqualified" ? t("Unqualify") : t("Archived")}
                </Button>{" "}
              </Box>
            </Box>
          </div>
        }

      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.menuList}
      >
        {
          ["Disqualify", "Archieved", "Unqualified"]?.map((x, index) => {
            return <Typography className={classes.menuItem1} onClick={() => {
              handleClose()
              leadClick(2, details, x)
            }}>{statusType[x]}</Typography>

          })
        }
      </Popover>

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.menuList}
      >
        {["to_an_individual", "against_team"]?.map((x, index) => {
          return (
            <Typography
              className={classes.menuItem1}
              onClick={() => clickType(x)}
            >
              {statusType1[x]}
            </Typography>
          );
        })}
      </Popover>

      {/* Convert Oppertunity */}
      {/* create section */}
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={create?.bool}
          onClose={() => handleCreate("bool", false)}
        >
          <CreateLead
            title={t("Convert To Opportunity")}
            onClose={() => handleCreate("bool", false)}
            convert_data={create?.data}
            company={create?.data?.company}
            is_convert={true}
            btnName={t("Oppertunity")}
            company_id={create?.data?.company?.value}
            proxy_id={leadId}
            onNext={convertOppertunity}
            is_lead={true}
            setState1={setState1}
            state1={state1}
            t={t}
          />
        </Drawer>
      </React.Fragment>

    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission)}
    </div>
  );
};
export default withNamespaces("leads")(LeadView);