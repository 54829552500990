import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Client, Profile, SerachIMG } from "../../assets";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, useWindowDimensions } from '../../utils';
import { ContactList } from "../contactlist";
import { LeadCard } from "./leadCard";
import { useStyles } from "./styles";
export const ExistingLead = ({ onClick = () => false, value = null, disable = false, companyId = "" , onClickLead=()=>false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const [expend, setExpend] = useState()
    const [leadList, setLeadlist] = useState([]);
    const [offset, setOffset] = React.useState(0)
    const [search, setSearch] = useState("")
    const [searchBool, setSearchBool] = useState(false)

    //get lead
    const getLeadList = (search, offset, searchBool) => {
        const payload = {
            company_id: companyId,
            search: search,
            offset: offset,
            limit: 10
          }
          NetworkCall(
            `${config.api_url}/queries/opportunity/get_by_company`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          ).then((response) => {
            if (searchBool) {
                setLeadlist(response.data.data?.lead);
            } else {
                setLeadlist(leadList.concat(response.data.data?.lead));
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //handle open 
    const handleOpen = () => {
        setExpend(!expend)
    }
    //handle search
    const handleSearch = (searchText) => {
        setSearch(searchText)
    }
    //fetch onscroll
    const fetchMoreData = () => {
        setOffset(offset + 10, false)
        getLeadList(search, offset + 10, false)
    }
    //submit search
    const submitSearch = () => {
        if (search?.length > 0) {
            getLeadList(search, 0, true)
            setSearchBool(true)
        }
    }
    //click lead
    const handleexisitlead = (val) => {
        setSearchBool(false)
        onClick(val)
    }
    //on switch lead
    const handleSwitchLead = () => {
        onClick(null)
        setExpend(true)
    }
    return (
        <Box display="flex" alignItems="flex-end">

            {expend || value ?
                <Box className={classes.closeDiv} onClick={handleOpen}>
                    <ArrowForwardIosIcon sx={{ fontSize:"0.875rem" }} />
                </Box>
                : ""
            }
            <Box flexGrow={1}>
                {
                    value ?

                        //lead details card
                        <Box className={classes.root} p={1}>
                            {
                                expend ?
                                    value?.contact?.get_assets_url_id?.length > 0 ?
                                        <img className={classes.image} src={value?.contact?.get_assets_url_id} alt="" />
                                        : <Profile />
                                    : <LeadCard disable={disable} data={value} onClick={handleSwitchLead} onClickLead={onClickLead} />
                            }

                        </Box>
                        :
                        <Box className={classes.root} p={1}>
                            {
                                expend ?
                                    //expen is true show search box
                                    <>
                                        {
                                            searchBool &&
                                            //seacrh is true show lead list
                                            <Box >
                                                {
                                                    leadList?.length > 0 ?
                                                        <InfiniteScroll
                                                            dataLength={leadList.length}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            height={size?.height - 400}
                                                        >
                                                            {leadList.map((val) => {
                                                                return (
                                                                    <Box p={0.5}>
                                                                        <ContactList data={{
                                                                            name: `${val?.contact?.first_name} ${val?.contact?.last_name ?? ""}`,
                                                                            img: val?.contact?.get_assets_url_id,
                                                                            arg: val?.lead_no,
                                                                            phone: val?.contact?.mobile_no,
                                                                            mail: val?.contact?.email,
                                                                            id: val?.id
                                                                        }}
                                                                            onClick={() => handleexisitlead(val)}
                                                                            leadtype={true} />
                                                                    </Box>
                                                                );
                                                            })}
                                                        </InfiniteScroll>
                                                        : <Typography textAlign="center" className={classes.name}>No Lead Found</Typography>
                                                }
                                            </Box>
                                        }

                                        <Box display="flex" alignItem="center" p={1}>

                                            <Box flexGrow={1}>
                                                <TextField fullWidth size="small" onChange={(e) => handleSearch(e.target.value)} />
                                            </Box>
                                            <Box marginLeft="8px">
                                                < IconButton className={classes.iconBtn} onClick={submitSearch}>
                                                    <SerachIMG color="#fff" />
                                                </IconButton>
                                            </Box>
                                        </Box>

                                    </>
                                    :
                                    //client btn 
                                    < IconButton className={classes.iconBtn} onClick={handleOpen}>
                                        <Client color="#fff" />
                                    </IconButton>
                            }

                        </Box>
                }

            </Box>
        </Box>
    )
}