import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../../components/dialogWithTable";
import { NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const OtherInfo = (props) => {
    const { t } = props
    const unitId = props?.unitID
    const [count, setCount] = React.useState({
        owners: 0,
        resident: 0,
        breakup: 0,
        vacancy: 0,
        amenities: 0,
        rooms: 0,
        requset: 0,
        agreement: 0,
        invoice: 0
    })
    React.useEffect(() => {
        const payload = {
            unit_id: unitId,
        }
        NetworkCall(
            `${config.api_url}/queries/get_counts_by_unit_id`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            const data = res?.data?.data;

            setCount({
                owners: data?.totalOwners?.[0]?.count_owner_profile_id,
                resident: data?.totalResident?.[0]?.count_tenant_profile_id,
                breakup: data?.pricing_table?.[0]?.count_id,
                vacancy: data?.unit_vacancy_period?.[0]?.count_id,
                amenities: data?.unit_amenities_breakup?.[0]?.count_id,
                rooms: data?.unit?.[0]?.count_id,
                requset: data?.general_request_units?.[0]?.count_id + data?.maintenance_request_units?.[0]?.count_id,
                agreement: data?.agreement_units?.[0]?.count_id,
                invoice: data?.invoice_units?.[0]?.count_id
            })
        })
        // eslint-disable-next-line
    }, [])
    const tableData = [
        {
            id: 8,
            title: "Owners & Landlords",
            color: "#E29336",
            count: count?.owners,
            table: {
                heading: [
                    { title: t("Owner Name"), field: 'ownerName', },
                    { title: t("Owner Phone Number"), field: 'ownerphoneno', },
                    { title: t("From"), field: 'from', },
                    { title: t("To"), field: 'to', },
                ],
                path: ["ownerName", "ownerphoneno", "from", "to"],
                dataType: [
                    { type: ["text"], name: "ownerName" },
                    { type: ["text"], name: "ownerphoneno" },
                    { type: ["text"], name: "from" },
                    { type: ["text"], name: "to" },
                ],
            },
            api_endpoint: "/queries/owner_customer/list",
            owner_customer_type: "owner",
            unitId: unitId
        },
        {
            id: 5,
            title: "Resident & Tenants",
            color: "#E53468",
            count: count?.resident,
            table: {
                heading: [
                    { title: t("Resident Name"), field: 'residentName', },
                    { title: t("Resident Phone Number"), field: 'residentphoneno', },
                    { title: t("From"), field: 'from', },
                    { title: t("To"), field: 'to', },
                ],
                path: ["residentName", "residentphoneno", "from", "to"],
                dataType: [
                    { type: ["text"], name: "residentName" },
                    { type: ["text"], name: "residentphoneno" },
                    { type: ["text"], name: "from" },
                    { type: ["text"], name: "to" },
                ],
            },
            api_endpoint: "/queries/owner_customer/list",
            owner_customer_type: "resident",
            unitId: unitId
        },
        {
            id: 0,
            title: "Pricing Table",
            count: count?.breakup,
            table: {
                heading: [
                    { title: t("Revenue Type"), field: 'revenue', },
                    { title: t("Component"), field: 'component', },
                    { title: t("Pricing Value"), field: 'value', },
                    { title: t("Pricing Currency"), field: "currency", },
                    { title: t("Chargable"), field: 'chargeable', },
                    { title: t("Taxable"), field: 'display', },
                    { title: t("TaxGroup"), field: 'taxGroup', },
                ],
                path: ["revenue", "component", "value", "currency", "chargeable", "display", "taxGroup"],
                dataType: [
                    { type: ["text"], name: "revenue" },
                    { type: ["text"], name: "component" },
                    { type: ["text"], name: "value" },
                    { type: ["text"], name: "currency" },
                    { type: ["text"], name: "chargeable" },
                    { type: ["text"], name: "display" },
                    { type: ["text"], name: "taxGroup" },
                ],
            },
            api_endpoint: "/queries/pricing_table/list",
            unitId: unitId,
            color: "#5078E1"
        },
        {
            id: 1,
            title: "Occupancy View",
            count: count?.vacancy,
            table: {
                heading: [
                    { title: t("Start Date"), field: "startDate", },
                    { title: t("End Date"), field: "endDate", },
                    { title: t("Occupancy Status"), field: "currentStatus", },
                    { title: t("Agreement Number"), field: "agreementNumber", },
                    { title: t("Company Name"), field: "companyName", },
                    { title: " ", field: "image", },
                    { title: t("Primary Contact Name"), field: "primaryContactName", },
                    { title: t("Primary Contact Number"), field: "primaryContactNumber", },
                    { title: t("Reservation Number"), field: "reservationNumber", },
                ],
                path: ["startDate", "endDate", "currentStatus", "agreementNumber", "image", "companyName", "primaryContactName", "primaryContactNumber", "reservationNumber"],
                dataType: [
                    { type: ["date"], name: "startDate" },
                    { type: ["date"], name: "endDate" },
                    { type: ["status"], name: "currentStatus" },
                    { type: ["text"], name: "agreementNumber" },
                    { type: ["text"], name: "companyName" },
                    { type: ["avatarmanagement"], name: "image" },
                    { type: ["text"], name: "primaryContactName" },
                    { type: ["text"], name: "primaryContactNumber" },
                    { type: ["text"], name: "reservationNumber" },
                ],
            },
            api_endpoint: "/queries/unit_vacancy/list",
            unitId: unitId,
            color: "#E29336"
        },
        {
            id: 2,
            title: "Amenities",
            count: count?.amenities,
            table: {
                heading: [
                    { title: t("Amenities Name"), field: 'name', },
                    { title: t("Chargeable"), field: 'chargable', },
                ],
                path: ["name", "chargable"],
                dataType: [
                    { type: ["text"], name: "name" },
                    { type: ["text"], name: "chargable" },
                ],
            },
            api_endpoint: "/queries/unit_amenities_breakup/list",
            unitId: unitId,
            color: "#339E89"
        },
        {
            id: 4,
            title: "Rooms & Bed Space",
            color: "#8887D4",
            count: count?.rooms,
            table: {
                heading: [
                    { title: t("Room Name"), field: 'roomName', },
                    { title: t("Description"), field: 'description', },
                    { title: t("Room Number"), field: 'roomno', },
                    { title: t("Room Status"), field: 'roomSttaus', },
                ],
                path: ["roomName", "description", "roomno", "roomSttaus"],
                dataType: [
                    { type: ["text"], name: "roomName" },
                    { type: ["text"], name: "description" },
                    { type: ["text"], name: "roomno" },
                    { type: ["status"], name: "roomSttaus" },
                ],
            },
            api_endpoint: "/queries/unit/list",
            count_type: "array",
            unitId: unitId
        },
        {
            id: 6,
            title: "Requests",
            color: "#30AFCE",
            count: count?.requset,
            table: {
                heading: [
                    { title: t("Request Id"), field: 'requestId', },
                    { title: t("Request Name"), field: 'requestName', },
                    { title: t("Raised Date"), field: 'raisedDate', },
                    { title: t("Category"), field: 'category', },
                    { title: t("Status"), field: 'status', },
                ],
                path: ["requestId", "requestName", "raisedDate", "category", "status"],
                dataType: [
                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "status" },
                ],
            },
            api_endpoint: "/queries/request/list",
            unitId: unitId
        },
        {
            id: 9,
            title: "Agreements",
            color: "#8887D4",
            count: count?.agreement,
            table: {
                heading: [
                    { title: t("Agreement Number"), field: 'agreementNo', },
                    { title: t("Valid From"), field: 'validfrom', },
                    { title: t("Valid To"), field: 'validto', },
                    { title: t("Agreement User"), field: 'agreementuser', },
                ],
                path: ["agreementNo", "validfrom", "validto", "agreementuser",],
                dataType: [
                    { type: ["text"], name: "agreementNo" },
                    { type: ["date"], name: "validfrom" },
                    { type: ["date"], name: "validto" },
                    { type: ["text"], name: "agreementuser" },
                ],
            },
            api_endpoint: "/queries/agreement_units/list",
            unitId: unitId
        },
    ]

    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2} sm={3} md={3} lg={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} t={t}/>
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}