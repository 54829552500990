import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { AlertDialog, LoadingSection, SearchFilter, SelectBox, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { BackendRoutes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods, accessCheckRender, concat_string, constructPropertyList, getCompanyOption, getRoutePermissionNew, stringAvatar, timeZoneConverter } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { AssetView } from '../resourceMaster/components/assetView';
import { useStyles } from "./style";
import { Heading, Path, Type, period_options } from './utils/tableUtils';

const Occupants = (props) => {
    const { loading, handleLoading, t } = props;

    const classes = useStyles();
    const debounce = UseDebounce();


    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);

    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [propertyList, setPropertyList] = React.useState([]);
    const [selectedProperty, setSelectedProperty] = React.useState({});
    const [selectedUnit, setSelectedUnit] = React.useState("");
    const [data, setData] = React.useState({});
    const [propertySearchText, setPropertySearchText] = React.useState("");
    const [occupantSearchText, setOccupantSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [permission, setPermission] = React.useState({});
    const [loader, setLoader] = React.useState(true);
    const [extraParams, setExtraParams] = React.useState({});
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [selectedPeriod, setSelectedPeriod] = React.useState(period_options(t)?.[0]?.value);
    const [isSearchPropertyDisabled, setIsSearchPropertyDisabled] = React.useState(true);
    const [isOccupantViewOpen, setIsOccupantViewOpen] = React.useState(false);
    const [occupantViewState, setOccupantViewState] = React.useState(false);

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list);
                    setSelectedCompany(company?.selected);

                    let final_property_list = getPropertyList(company?.selected);
                    setPropertyList(final_property_list);
                    if (final_property_list?.length > 0) {
                        setSelectedProperty(final_property_list?.[0]);

                        let temp_extra_params = {
                            company_id: company?.selected?.value,
                            period: period_options(t)?.[0]?.value,
                        };
                        setExtraParams(temp_extra_params);

                        getData(0, limit, occupantSearchText, temp_extra_params);
                    } else {
                        setSelectedProperty({});
                        setData({
                            row: [],
                            totalRowsCount: 0,
                        });
                        setLoader(false);
                        handleLoading(false);
                        setIsDisabled(false);
                        setIsSearchPropertyDisabled(false);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getPropertyList = (company) => {
        let temp_property_list = constructPropertyList(company);

        let final_property_list = [];

        switch (temp_property_list?.list?.length) {
            case 0:
                final_property_list = [];
                break;

            case 1:
                final_property_list = temp_property_list?.list;
                break;

            default:
                final_property_list = [
                    {
                        label: t("All"),
                        value: "all_properties",
                        unit_purpose_property_ids: temp_property_list?.list?.map?.(_ => _?.value),
                    },
                    ...temp_property_list?.list,
                ]
                break;
        }

        return final_property_list;
    }

    const getData = (offset = 0, limit = 10, search = "", extra_params = extraParams) => {
        let payload = {
            offset, limit, search,
            current_date: timeZoneConverter(new Date(), "DD MMM YY"),
            ...extra_params,
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.occupants_get}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((r) => {
            setPage(1);
            let temp_response = r?.data?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                let lease_period = concat_string(
                    {
                        lease_start_date: _?.lease_start_date && timeZoneConverter(_?.lease_start_date, "DD MMM YY"),
                        lease_end_date: _?.lease_end_date && timeZoneConverter(_?.lease_end_date, "DD MMM YY"),
                    },
                    ["lease_start_date", "lease_end_date"],
                    " - "
                );
                return {
                    unit: concat_string(
                        {
                            unit_name: _?.unit_name,
                            unit_no: _?.unit_no,
                        },
                        ["unit_name", "unit_no"],
                        ", "
                    ),
                    name: _?.name ?? "-",
                    gender: _?.gender ?? "-",
                    relationship: _?.relationship ?? "-",
                    proof_type: _?.proof_type ?? "-",
                    proof_id: _?.proof_id?.length > 0 ? _?.proof_id : "-",
                    lease_period,
                    is_active: _?.is_active ? "Active" : "Inactive",
                    data: {
                        ..._,
                        view_details: [
                            { label: "Name", value: _?.name ?? "-" },
                            { label: "Gender", value: _?.gender ?? "-" },
                            { label: "Relationship", value: _?.relationship ?? "-" },
                            { label: "Proof Type", value: _?.proof_type ?? "-" },
                            { label: "Proof ID", value: _?.proof_id?.length > 0 ? _?.proof_id : "-" },
                            { label: "Lease Period", value: lease_period },
                            { label: "Status", value: _?.is_active ? "Active" : "Inactive" },
                        ]
                    },
                }
            })
            setData({
                row: temp_row ?? [],
                totalRowsCount: r?.data?.data?.count ?? 0
            });
            setLoader(false);
            handleLoading(false);
            setIsDisabled(false);
            setIsSearchPropertyDisabled(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps?.severity?.error
            });
            setIsDisabled(false);
            setIsSearchPropertyDisabled(false);
        });
    }

    const handlePropertySearch = (e) => {
        setIsDisabled(true);
        let temp_property_list = getPropertyList(selectedCompany);
        let final_property_list = [];
        setPropertySearchText(e);
        if (e?.length > 0) {
            final_property_list = temp_property_list
                ?.filter?.(i_1 => i_1?.value !== "all_properties")
                ?.filter?.(i_2 => i_2?.label?.toLowerCase()?.includes?.(e.toLowerCase()));
        } else {
            final_property_list = temp_property_list;
        }
        setPropertyList(final_property_list);
        setSelectedProperty(final_property_list?.[0]);
        setSelectedUnit("");
        debounce(() => {
            if (e?.toLowerCase() !== propertySearchText?.toLowerCase()) {
                setOccupantSearchText("");
                if (final_property_list?.length > 0) {
                    let temp_extra_params = final_property_list?.[0]?.value === "all_properties"
                        ? { company_id: selectedCompany?.value }
                        : { property_ids: [final_property_list?.[0]?.value] };
                    temp_extra_params["period"] = selectedPeriod;
                    setExtraParams(temp_extra_params);
                    getData(0, limit, occupantSearchText, temp_extra_params);
                } else {
                    setSelectedProperty({});
                    setData({
                        row: [],
                        totalRowsCount: 0,
                    });
                    setIsDisabled(false);
                }
            } else {
                setIsDisabled(false);
            }
        }, 800);
    }

    const handleOccupantSearch = (e) => {
        setOccupantSearchText(e);
        debounce(() => searchTableFunction(e), 800);
    }

    const searchTableFunction = (e) => {
        getData(0, limit, e, extraParams);
    }

    const handleTablePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getData(offset, limit, occupantSearchText, extraParams);
    }

    const handleTablePageLimit = (value) => {
        setLimit(value);
        getData(0, value, occupantSearchText, extraParams);
    }

    const handleCompanyChange = (value) => {
        setIsDisabled(true);
        setIsSearchPropertyDisabled(true);
        setSelectedCompany(value);
        setSelectedUnit("");
        debounce(() => {
            let final_property_list = getPropertyList(value);
            setPropertyList(final_property_list);

            if (final_property_list?.length > 0) {
                setSelectedProperty(final_property_list?.[0]);
                let temp_extra_params = {
                    company_id: value?.value,
                    period: selectedPeriod,
                };
                setExtraParams(temp_extra_params);
                getData(0, limit, occupantSearchText, temp_extra_params);
            } else {
                setSelectedProperty({});
                setData({
                    row: [],
                    totalRowsCount: 0,
                });
                setIsDisabled(false);
                setIsSearchPropertyDisabled(true);
            }
        }, 800);
    }

    const handlePropertyChange = (value) => {
        if (!isDisabled && value?.value !== selectedProperty?.value) {
            setIsDisabled(true);
            setIsSearchPropertyDisabled(true);
            setSelectedProperty(value);
            setSelectedUnit("");
            debounce(() => {
                let temp_extra_params = value?.value === "all_properties"
                    ? { company_id: selectedCompany?.value }
                    : { property_ids: [value?.value] };
                temp_extra_params["period"] = selectedPeriod;
                setExtraParams(temp_extra_params);
                getData(0, limit, occupantSearchText, temp_extra_params);
            }, 800);
        }
    }

    const handlePeriodChange = (value) => {
        if (!isDisabled && value && value !== selectedPeriod) {
            setSelectedPeriod(value);
            setIsDisabled(true);
            setIsSearchPropertyDisabled(true);
            debounce(() => {
                let temp_extra_params = {
                    ...extraParams,
                    period: value,
                };
                setExtraParams(temp_extra_params);
                getData(0, limit, occupantSearchText, temp_extra_params);
            }, 800);
        }
    }


    const handleUnitChange = (value) => {
        if (!isDisabled && value?.value !== selectedUnit?.value) {
            setIsDisabled(true);
            setIsSearchPropertyDisabled(true);
            setSelectedUnit(value);
            debounce(() => {
                let temp_extra_params = value?.value === "all_units"
                    ? (selectedProperty?.value === "all_properties"
                        ? { company_id: selectedCompany?.value }
                        : { property_ids: [selectedProperty?.value] })
                    : { unit_ids: [value?.value] };
                temp_extra_params["period"] = selectedPeriod;
                setExtraParams(temp_extra_params);
                getData(0, limit, occupantSearchText, temp_extra_params);
            }, 800);
        }
    }

    const handleOccupantViewOpen = (value) => {
        if (!isDisabled) {
            setIsOccupantViewOpen(true);
            setOccupantViewState(value?.data);
        }
    }

    const handleOccupantViewClose = () => {
        if (!isDisabled) {
            setIsOccupantViewOpen(false);
            setOccupantViewState({});
        }
    }
    const manualResponse = (array) => {
        const details = array?.unit?.map((i) => {
            return {
                label: `${i?.label}, ${i?.unit_no ?? ""}`,
                value: i?.value
            }
        })
        return details
    };
    const render = () => {
        return <Box>
            <Subheader hideBackButton={true} title={t("Occupants")}
                select options={companyList} value={selectedCompany}
                isReadOnly={isDisabled} onchange={(e) => handleCompanyChange(e)} />
            {loader
                ? (<NewLoader minusHeight="100px" />)
                : (<Box className={classes.root}>
                    <Grid container spacing={"14px"}>
                        <Grid item xs={3}>
                            <Box className={classes.property_content}>
                                <Box p={"0px 16px"}>
                                    <SearchFilter isReadonly={isSearchPropertyDisabled}
                                        value={propertySearchText} placeholder={t("Search Property")}
                                        handleChange={(value) => handlePropertySearch(value)} />
                                </Box>
                                <Box p={"8px 16px"} height={`calc(100vh - 204px)`} overflow={"overlay"}>
                                    {propertyList?.length > 0
                                        ? (propertyList?.map((_) => {
                                            return <Box
                                                display={"flex"}
                                                sx={{
                                                    cursor: selectedProperty?.value === _?.value
                                                        ? "default"
                                                        : isDisabled
                                                            ? "wait"
                                                            : "pointer"
                                                }}
                                                onClick={() => handlePropertyChange(_)}
                                                className={selectedProperty?.value === _?.value
                                                    ? classes.selected_property_card
                                                    : classes.unselected_property_card
                                                }>
                                                <Box>
                                                    <Avatar src={_?.property_logo} {...stringAvatar(_?.label)} />
                                                </Box>
                                                <Box sx={{ marginInlineStart: "8px" }}>
                                                    <Typography
                                                        className={selectedProperty?.value === _?.value
                                                            ? classes.selected_property_name
                                                            : classes.unselected_property_name
                                                        }>
                                                        {_?.label ? _?.label : "-"}
                                                        <Typography
                                                            className={selectedProperty?.value === _?.value
                                                                ? classes.selected_property_no
                                                                : classes.unselected_property_no
                                                            }>
                                                            {_?.value === "all_properties"
                                                                ? t("Properties")
                                                                : _?.property_no
                                                                    ? _?.property_no
                                                                    : "-"
                                                            }
                                                        </Typography >
                                                    </Typography >
                                                </Box >
                                            </Box >
                                        }))
                                        : <Typography className={classes.no_data_text}>
                                            {t("No Properties Found")}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box className={classes.occupant_content}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <SearchFilter isReadonly={isDisabled}
                                            value={occupantSearchText} placeholder={t("Search Occupants")}
                                            handleChange={(value) => handleOccupantSearch(value)} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container display={"flex"} justifyContent={"end"} columnGap={1}>
                                            <Stack direction={"row"} columnGap={"4px"}>
                                                {period_options(t)?.map(
                                                    _ => {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    cursor: _?.value === selectedPeriod
                                                                        ? "default"
                                                                        : isDisabled
                                                                            ? "wait"
                                                                            : "pointer"
                                                                }}
                                                                className={
                                                                    _?.value === selectedPeriod
                                                                        ? classes.selected_period_box
                                                                        : classes.unselected_period_box
                                                                }
                                                                onClick={() => handlePeriodChange(_?.value)}>
                                                                <Typography
                                                                    className={
                                                                        _?.value === selectedPeriod
                                                                            ? classes.selected_period_text
                                                                            : classes.usselected_period_text
                                                                    }>
                                                                    {_?.label}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            </Stack>
                                            <SelectBox
                                                key={JSON.stringify(extraParams)}
                                                isReadOnly={isDisabled}
                                                placeholder={t("Select Unit")}
                                                selectHeight="38px"
                                                width="250px"
                                                value={selectedUnit}
                                                onChange={(value) => handleUnitChange(value)}
                                                isPaginate={true}
                                                debounceTimeout={800}
                                                // loading={isDropDownLoading === "units"}
                                                loadOptions={(search, array, handleLoading) =>
                                                    loadOptionsApis(
                                                        "queries/unit/list",
                                                        {
                                                            "property_ids": selectedProperty?.value === "all_properties"
                                                                ? selectedProperty?.unit_purpose_property_ids
                                                                : [selectedProperty?.value],
                                                            "execution_status": ["Release"],
                                                            "status": [true]
                                                        },
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        "data",
                                                        {},
                                                        manualResponse
                                                    )
                                                } />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableWithPagination
                                    is_loading={isDisabled}
                                    heading={Heading(t)}
                                    rows={data?.row}
                                    path={Path}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    handleIcon={() => false}
                                    onClick={handleOccupantViewOpen}
                                    tableType="no-side"
                                    dataType={Type}
                                    handlePagination={handleTablePagination}
                                    handleChangeLimit={handleTablePageLimit}
                                    totalRowsCount={data?.totalRowsCount}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 290px)'}
                                    view={true}
                                    edit={true}
                                    delete={true}
                                    noDataText={t("No Occupants Found")} />
                            </Box>
                        </Grid>
                    </Grid>
                    <AlertDialog
                        open={isOccupantViewOpen}
                        medium={true}
                        header={t("Occupant View")}
                        onClose={handleOccupantViewClose}
                        component={
                            <Box>
                                {occupantViewState?.id
                                    ? <Box>
                                        <Box p={2}>
                                            {occupantViewState?.profile_picture?.length > 0 ?
                                                <AssetView
                                                    asset={[{ url: occupantViewState?.profile_picture }]}
                                                />
                                                :
                                                <img src="/images/profile_image.png" alt="" className={classes.occupant_default_image} />
                                            }
                                        </Box>
                                        <Box className={classes.occupant_details_content} >
                                            <Grid container spacing={2} justifyContent={"auto"}>
                                                {occupantViewState?.view_details?.map(_ =>
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Typography className={classes.occupant_details_label} noWrap>
                                                            {t(_?.label)}
                                                        </Typography>
                                                        <Typography noWrap className={
                                                            auth?.auth?.auth?.language === 'ar'
                                                                ? classes.occupant_details_value_rtl
                                                                : classes.occupant_details_value_ltr}>
                                                            {_?.value}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Box>
                                    : <LoadingSection top="30vh" bottom="30vh" />
                                }
                            </Box>
                        } />
                </Box>)}
        </Box>
    }

    return <Box>
        {accessCheckRender(render, permission, "", loading)}
    </Box>
}
export default withNamespaces("itemCategoryMaster")(Occupants)
