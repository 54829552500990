import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import { AlertDialog, MapFieldsOnly, MobileNumberInputComponent, SearchFilter, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { Bold, enum_types, enumSelect, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { useStylesCreation } from "../style";
import { AddMemberCard, Card, Card1 } from "./addmembercard";

const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: "0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

export const GeneralDetails = (props) => {
      const localStorageCompany = JSON.parse(localStorage?.getItem?.(LocalStorageKeys?.selectedCompany));
      const currency_code = localStorageCompany?.code ?? "";
      const { t = () => false } = (props);
      const list = [
            {
                  name: t("Reporting To")
            }
      ]
      const size = useWindowDimensions()
      const classes = useStylesCreation();
      const [imageViwer, setImageViwer] = React.useState(false)
      const [deleteUser, setdeleteUser] = React.useState(false)
      const [selectedID, setSelectedID] = React.useState(null)
      const [open, setOpen] = React.useState(false)
      const [searchdata, setSearchdata] = React.useState("")
      const [offset, setOffset] = React.useState(0)
      const [userDetails, setUserDetails] = React.useState([])

      const [enumValue, setEnumValue] = React.useState({
            relationship: [],
            method: [],
            emergencyContactRelationship: [],
            sex: []
      })
      
      const getEnum = async () => {
            const result = await enumSelect([
                  enum_types.relationship_type, enum_types.preferred_mode_contact_type,
                  enum_types.emergency_relationship_type, enum_types.sex_type
            ])
            setEnumValue({
                  relationship: result?.relationship_type?.filter(i => !Boolean(["Prospect", "Customer"]?.includes(i?.value))),
                  method: result?.preferred_mode_contact_type,
                  emergencyContactRelationship: result?.emergency_relationship_type,
                  sex: result?.sex_type
            })
      }
      React.useEffect(() => {
            getEnum()
      }, [])

      const selectUser = (x) => {
            setSelectedID(x)
      }

      const deleteMember = () => {
            props?.updateState('member', {})
            setSelectedID(null)
            setOpen(!open)
      }

      const closeDrawer = (v, k) => {

            if (k === 'delete') {
                  setdeleteUser(true)
                  setOpen(!open)
            }
            else if (k === 'add') {
                  setdeleteUser(false)
                  setOpen(!open)
                  props?.updateState('member', {})
            }
            else if (k === 'close') {
                  props?.updateState('member', {})
                  setSelectedID(null)
                  setOpen(!open)
            }
            else if (k === 'deleteclose') {
                  setOpen(!open)
            }
            else {
                  props?.updateState('member', selectedID)
                  setOpen(!open)
            }

      }

      const getUserProfile = (v, offset, search, k) => {
            const payload = {
                  company_id: props?.data?.company?.value,
                  id: v?.value,
                  offset: offset,
                  limit: 10,
                  search: search ?? "",
            }

            NetworkCall(
                  `${config.api_url}/queries/get_contact_by_department`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            ).then((res) => {
                  if (k === 'search') {
                        setUserDetails(res?.data?.data?.contact)
                  }
                  else {
                        setUserDetails(userDetails?.concat(res?.data?.data?.contact));
                  }
            }).catch(() => {

            })
      }

      const updateState = (k, v) => {
            props?.updateState(k, v);
            if (k === 'otherDepartment') {
                  getUserProfile(v, 0, "", "search")
            }
      }

      const fetchMoreData = () => {
            setOffset(offset + 10);
            getUserProfile(props?.data?.otherDepartment, offset + 10, "", "normal");
      };

      const handleSearch = (v) => {
            setSearchdata(v)
            getUserProfile(props?.data?.otherDepartment, 0, v, "search")
      }

      const relationshipHidden = ["Service Provider", "External Vendor Employee", "Vendor"]
      const memberList = ["Company Employee", "External Vendor Employee"]
      const vendorCondition = ["External Sales Broker", "External Vendor Employee" , "Vendor", "Agent"]

      const manualJobResponse = (array) => {
            return array?.data
      }

      const manualDeptResponse = (array) => {
            return array?.department_master
      }

      const manualServiceProviderResponse = (array) => {
            return array?.service_provider_master
      }

      const manualVendorResponse = (array) => {
            return array?.vendor_master
      }

      const manualDepartmentResponse = (array)=>{
            return array?.department
      }

      return (
            <div>
                  <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={2}>
                              <Box className={classes.imagebox}>
                                    <CustomTypography>
                                          {t("Profile Picture")}
                                    </CustomTypography>
                                    <Avatar src={props?.data?.profileImage?.length > 0 ? props?.data?.profileImage : "/images/citynew.svg"}
                                          className={props?.data?.profileImage?.length > 0 ? classes.avatar : classes.avatar1} onClick={() => setImageViwer(true)} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained'>{t("Upload image")}</Box> <input type='file' className={classes.inputFileStyle} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null;

                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    </div>
                                    {props?.data?.profileImage?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>{t("Remove Image")}</Typography> : props?.data?.error?.profileImage?.length > 0 ? (
                                          <span className={classes.profileImageErrorTextStyle}>
                                                {t("Profile Image is required")}
                                          </span>
                                    ) : <Box height={"18px"} />}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={10}>
                              <Box className={classes.profileDetailCardStyle}>
                                    <CustomTypography>
                                          {t("Profile Details")}
                                    </CustomTypography>
                                    <Grid container spacing={2}>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                {props?.data?.isAllCompany ?
                                                      <SelectBox
                                                            isRequired
                                                            label={t("Company")}
                                                            options={props?.data?.companyList}
                                                            placeholder={t("Select Company")}
                                                            value={props?.data?.company}
                                                            onChange={(value) => {
                                                                  props?.updateState("company", value);
                                                            }}
                                                            isError={props?.data?.error?.company?.length > 0}
                                                            errorMessage={props?.data?.error?.company} /> :
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Company Name")}
                                                            placeholder={t("Company Name")}
                                                            value={props?.data?.company?.label ?? null}
                                                            onChange={(e) => {
                                                                  props?.updateState("company", e.target.value);
                                                            }}
                                                      />
                                                }
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={props?.type === "Agent" ? 3 : 6}>
                                                <TextBox
                                                      isrequired
                                                      label={t("Name")}
                                                      placeholder={t("Enter Name")}
                                                      value={props?.data?.name ?? null}
                                                      onChange={(e) => {
                                                            props?.updateState("name", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.name?.length > 0}
                                                      errorMessage={props?.data?.error?.name} />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label={t("Relationship")}
                                                      options={enumValue?.relationship}
                                                      placeholder={t("Select Relationship")}
                                                      value={props?.data?.relationship && enumValue?.relationship?.find((i) => (i.value === props?.data?.relationship?.value))}
                                                      isReadOnly={props?.type === "Agent"}
                                                      onChange={(value) => {
                                                            props?.updateState("relationship", value);
                                                      }}
                                                      isError={props?.data?.error?.relationship?.length > 0}
                                                      errorMessage={props?.data?.error?.relationship} />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      label={t("AR Primary Account")}
                                                      placeholder={t("Select AR Primary Account")}
                                                      value={props?.data?.primary_account}
                                                      onChange={(value) => {
                                                            props?.updateState("primary_account", value);
                                                      }}
                                                      isError={props?.data?.error?.primary_account?.length > 0}
                                                      errorMessage={props?.data?.error?.primary_account}
                                                      debounceTimeout={800}
                                                      loadOptions={
                                                            (search, array, handleLoading) =>
                                                                  loadOptionsApis(
                                                                        "queries/opportunity/contact_account",
                                                                        { "account_payment_classification": "AR" },
                                                                        search, array, handleLoading, "data",
                                                                  )
                                                      }
                                                      isPaginate
                                                      key={JSON.stringify(props?.data?.relationship)}
                                                />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      label={t("AP Primary Account")}
                                                      placeholder={t("Select AP Primary Account")}
                                                      value={props?.data?.ap_primary_account}
                                                      onChange={(value) => {
                                                            props?.updateState("ap_primary_account", value);
                                                      }}
                                                      isError={props?.data?.error?.ap_primary_account?.length > 0}
                                                      errorMessage={props?.data?.error?.ap_primary_account}
                                                      debounceTimeout={800}
                                                      loadOptions={
                                                            (search, array, handleLoading) =>
                                                                  loadOptionsApis(
                                                                        "queries/opportunity/contact_account",
                                                                        { "account_payment_classification": "AP" },
                                                                        search, array, handleLoading, "data",
                                                                  )
                                                      }
                                                      isPaginate
                                                      key={JSON.stringify(props?.data?.relationship)}
                                                />
                                          </Grid>
                                          {
                                                !relationshipHidden?.includes(props?.data?.relationship?.value)
                                                &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <SelectBox
                                                            isRequired
                                                            label={t("Gender")}
                                                            options={enumValue?.sex}
                                                            placeholder={t("Select Gender")}
                                                            value={props?.data?.sex}
                                                            onChange={(value) => {
                                                                  props?.updateState("sex", value);
                                                            }}
                                                            isError={props?.data?.error?.sex?.length > 0}
                                                            errorMessage={props?.data?.error?.sex} />
                                                </Grid>
                                          }


                                          {props?.data?.relationship?.value === "Other" &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <TextBox
                                                            isrequired
                                                            label={t("Enter Relationship")}
                                                            placeholder={t("Enter  Relationship")}
                                                            value={props?.data?.otherRelationship ?? null}
                                                            onChange={(e) => {
                                                                  props?.updateState("otherRelationship", e.target.value);
                                                            }}
                                                            isError={props?.data?.error?.otherRelationship?.length > 0}
                                                            errorMessage={props?.data?.error?.otherRelationship} />
                                                </Grid>
                                          }
                                          {
                                                !relationshipHidden?.includes(props?.data?.relationship?.value)
                                                &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <SelectBox
                                                            label={t("Nationality")}
                                                            placeholder={t("Enter Nationality")}
                                                            value={props?.data?.nationality ?? null}
                                                            isPaginate
                                                            debounceTimeout={800}
                                                            loadOptions={
                                                                  (search, array, handleLoading) =>
                                                                        loadOptionsApis(
                                                                              "queries/opportunity/country_master",
                                                                              {},
                                                                              search,
                                                                              array,
                                                                              handleLoading,
                                                                              "data",
                                                                        )
                                                            }
                                                            onChange={(value) => props?.updateState("nationality", value)}
                                                            isRequired
                                                            isError={props?.data?.error?.nationality?.length > 0}
                                                            errorMessage={props?.data?.error?.nationality}
                                                      />

                                                </Grid>
                                          }
                                          {
                                                (props?.type === "Agent" || vendorCondition.includes(props?.data?.relationship?.value)) &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <SelectBox
                                                            label={t("Vendor Profile")}
                                                            placeholder={t("Select Vendor Profile")}
                                                            value={props?.data?.vendorlist ?? null}
                                                            isPaginate
                                                            isRequired
                                                            debounceTimeout={800}
                                                            loadOptions={
                                                                  (search, array, handleLoading) =>
                                                                        loadOptionsApis(
                                                                              "queries/vendor_master/list",
                                                                              {
                                                                                    company_id: props?.data?.company?.value
                                                                              },
                                                                              search,
                                                                              array,
                                                                              handleLoading,
                                                                              "data",
                                                                              {},
                                                                              manualVendorResponse
                                                                        )
                                                            }
                                                            onChange={(value) => props?.updateState("vendorlist", value)}
                                                            isError={props?.data?.error?.vendorlist?.length > 0}
                                                            errorMessage={props?.data?.error?.vendorlist}
                                                      />
                                                </Grid>
                                          }


                                          {
                                                props?.data?.relationship?.value === "Service Provider" &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <SelectBox
                                                            label={t("Service Provider")}
                                                            placeholder={t("Select Service Provider")}
                                                            value={props?.data?.serviceProvider ?? null}
                                                            isPaginate
                                                            isRequired
                                                            debounceTimeout={800}
                                                            loadOptions={
                                                                  (search, array, handleLoading) =>
                                                                        loadOptionsApis(
                                                                              "queries/service_provider/list",
                                                                              {
                                                                                    company_id: props?.data?.company?.value
                                                                              },
                                                                              search,
                                                                              array,
                                                                              handleLoading,
                                                                              "data",
                                                                              {},
                                                                              manualServiceProviderResponse
                                                                        )
                                                            }
                                                            onChange={(value) => props?.updateState("serviceProvider", value)}
                                                            isError={props?.data?.error?.serviceProvider?.length > 0}
                                                            errorMessage={props?.data?.error?.serviceProvider}
                                                      />
                                                </Grid>
                                          }
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>
                  {
                        memberList.includes(props?.data?.relationship?.value) &&
                        <Box className={classes.socialProfileDetailCardStyle} >
                              <Grid container spacing={2} alignItems={"center"}>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <TextBox
                                                label={t("Job Title")}
                                                placeholder={t("Enter Job Title")}
                                                value={props?.data?.jobTitle ?? null}
                                                onChange={(e) => {
                                                      props?.updateState("jobTitle", e.target.value);
                                                }} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={3}>
                                          <TextBox
                                                isrequired
                                                placeholder={t("Company ID Card")}
                                                label={t("Company ID Card")}
                                                value={props?.data?.employee_id ?? null}
                                                onChange={(e) => {
                                                      props?.updateState("employee_id", e.target.value);
                                                }}
                                                isError={props?.data?.error?.employee_id?.length > 0}
                                                errorMessage={props?.data?.error?.employee_id} />

                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array, handleLoading) =>
                                                      loadOptionsApis(
                                                            "queries/job_master",
                                                            {
                                                                  company_id: localStorageCompany?.value
                                                            },
                                                            search,
                                                            array,
                                                            handleLoading,
                                                            "data",
                                                            {},
                                                            manualJobResponse
                                                      )}
                                                label={t("Job & Roles")}
                                                placeholder={t("Select Job & Roles")}
                                                value={props?.data?.role ?? null}
                                                onChange={(value) => {
                                                      props?.updateState("role", value);
                                                }}
                                                isError={props?.data?.error?.role?.length > 0}
                                                errorMessage={props?.data?.error?.role} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array, handleLoading) =>
                                                      loadOptionsApis(
                                                            "queries/opportunity/department_master/get",
                                                            {
                                                                  company_ids: [localStorageCompany?.value]
                                                            },
                                                            search,
                                                            array,
                                                            handleLoading,
                                                            "data",
                                                            {},
                                                            manualDeptResponse
                                                      )}
                                                label={t("Department")}
                                                placeholder={t("Select Department")}
                                                value={props?.data?.Department ?? null}
                                                onChange={(value) => {
                                                      props?.updateState("Department", value);
                                                }}
                                                isError={props?.data?.error?.Department?.length > 0}
                                                errorMessage={props?.data?.error?.Department} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <div style={{ marginTop: '4px', marginLeft: "18px" }}>
                                                <AddMemberCard t={t} isNormal data={list} closeDrawer={closeDrawer} member={props?.data?.member} deleteUser={deleteUser} />
                                                <AlertDialog
                                                      isNormal
                                                      header={deleteUser === true ? t("Delete Member") : t("Add Member")}
                                                      onClose={() => closeDrawer({}, deleteUser === true ? 'deleteclose' : 'close')}
                                                      open={open}
                                                      component={
                                                            <>
                                                                  {
                                                                        deleteUser === false &&
                                                                        <div style={{ padding: "24px", }}>
                                                                              <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                          <SelectBox
                                                                                                isPaginate
                                                                                                debounceTimeout={800}
                                                                                                loadOptions={(search, array, handleLoading) =>
                                                                                                      loadOptionsApis(
                                                                                                            "queries/department_master/get",
                                                                                                            {
                                                                                                                  "company_id": props?.data?.company?.value,
                                                                                                            },
                                                                                                            search,
                                                                                                            array,
                                                                                                            handleLoading,
                                                                                                            "data",
                                                                                                            {},
                                                                                                            manualDepartmentResponse
                                                                                                      )}
                                                                                                label={t("Department")}
                                                                                                placeholder={t("Select Department")}
                                                                                                value={props?.data?.otherDepartment ?? null}
                                                                                                onChange={(value) => {
                                                                                                      updateState("otherDepartment", value);
                                                                                                }}
                                                                                                isError={props?.data?.error?.otherDepartment?.length > 0}
                                                                                                errorMessage={props?.data?.error?.otherDepartment} />
                                                                                    </Grid>
                                                                              </Grid>
                                                                        </div>
                                                                  }

                                                                  <div style={{
                                                                        textAlign: "center",
                                                                        padding: "24px",
                                                                  }}>


                                                                        <div id={'infinity'}>
                                                                              {
                                                                                    deleteUser === false &&
                                                                                    <div style={{ marginBottom: '24px' }}>
                                                                                          <SearchFilter
                                                                                                value={searchdata}
                                                                                                handleChange={(value) => handleSearch(value)}
                                                                                                placeholder={t("Search Member Details")}
                                                                                          />
                                                                                    </div>
                                                                              }



                                                                              <InfiniteScroll
                                                                                    dataLength={userDetails?.length ?? null}
                                                                                    next={fetchMoreData}
                                                                                    hasMore={true}
                                                                                    height={size?.height - 500}
                                                                              >
                                                                                    {
                                                                                          deleteUser === true ? <Card1 t={t} member={props?.data?.member} deleteMember={deleteMember} /> : <Card t={t} data={userDetails} selectUser={selectUser} select={selectedID} />
                                                                                    }

                                                                              </InfiniteScroll>



                                                                        </div>
                                                                        {
                                                                              deleteUser === false && <Button variant='contained' className={classes.buttoncard} onClick={closeDrawer}> {t("Add Member")}</Button>
                                                                        }
                                                                  </div>
                                                            </>
                                                      }
                                                />
                                          </div>
                                    </Grid>
                              </Grid>
                        </Box>
                  }
                  <div className='map-wrapper'>
                        <Box className={classes.addressDetailCardStyle} >
                              <CustomTypography>{t("Address")}</CustomTypography>
                              {
                                    <MapFieldsOnly

                                          fields={[
                                                {
                                                      label: t("Door Number"),
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Door Number"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("Address Line 1"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 1"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("Address Line 2"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 2"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("Landmark"),
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: t("Enter Landmark"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("Area"),
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: t("Enter Area"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("City"),
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: t("Enter City"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("State"),
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: t("Enter State"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: t("Country"),
                                                      component: "SelectBox",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: t("Select Country"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 },
                                                      loadOptions: (search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                  "queries/opportunity/country_master",
                                                                  {},
                                                                  search,
                                                                  array,
                                                                  handleLoading,
                                                                  "data",
                                                            )

                                                },
                                                {
                                                      label: t("Pincode"),
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: t("Enter Pincode"),
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }
                        </Box>
                  </div>
                  <Box className={classes.contactAndOtherDetailCardStyle} >
                        <CustomTypography>
                              {t("Contact & Other Information")}
                        </CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={4} lg={3.5}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={t("Telephone Number")}
                                          placeholder={t("Enter Telephone Number")}
                                          value={props?.data?.primaryTelephone}
                                          handleChange={(value) => {
                                                props?.updateState("primaryTelephone", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryTelephone?.length > 0}
                                          errorMessage={props?.data?.error?.primaryTelephone} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4} lg={3.5}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={t("Mobile Number")}
                                          placeholder={t("Enter Mobile Number")}
                                          value={props?.data?.primaryMobile}
                                          handleChange={(value) => {
                                                props?.updateState("primaryMobile", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryMobile?.length > 0}
                                          errorMessage={props?.data?.error?.primaryMobile} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4} lg={2.5}>
                                    <TextBox
                                          label={t("Email Address")}
                                          isrequired
                                          placeholder={t("Enter Email Address")}
                                          value={props?.data?.primaryEmail ?? null}
                                          onChange={(value) => {
                                                props?.updateState("primaryEmail", value?.target?.value);
                                          }}
                                          isError={props?.data?.error?.primaryEmail?.length > 0 || props?.already_exist?.error?.primaryEmail?.length > 0}
                                          errorMessage={props?.data?.error?.primaryEmail || props?.already_exist?.error?.primaryEmail} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4} lg={2.5}>
                                    <TextBox
                                          label={t("Login Email Address")}
                                          placeholder={t("Enter Login Email Address")}
                                          value={props?.data?.login_email ?? null}
                                          onChange={(value) => {
                                                props?.updateState("login_email", value?.target?.value);
                                          }}
                                          isError={props?.data?.error?.login_email?.length > 0 || props?.already_exist?.error?.login_email?.length > 0}
                                          errorMessage={props?.data?.error?.login_email || props?.already_exist?.error?.login_email} />
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={classes.leadAndOpportunityDetailCardStyle}>
                        <CustomTypography>{t("Lead & Opportunity Management")}</CustomTypography>
                        <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          endAdornment={(currency_code ?? "")}
                                          label={t("Last Revenue Target")}
                                          placeholder={t("Enter Last Revenue Target")}
                                          type={"number"}
                                          value={props?.data?.last_revenue_target ?? null}
                                          onChange={(e) => {
                                                props?.updateState("last_revenue_target", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          endAdornment={(currency_code ?? "")}
                                          label={t("Current Revenue Target")}
                                          placeholder={t("Enter Current Revenue Target")}
                                          type={"number"}
                                          value={props?.data?.current_revenue_target ?? null}
                                          onChange={(e) => {
                                                props?.updateState("current_revenue_target", e.target.value);
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={classes.socialProfileDetailCardStyle} >
                        <CustomTypography>{t("Social Profile")}</CustomTypography>
                        <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label={t("Whatsapp")}
                                          placeholder={t("Enter Whatsapp Number")}
                                          type={"number"}
                                          value={props?.data?.whatsApp ?? null}
                                          onChange={(e) => {
                                                props?.updateState("whatsApp", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label={t("Facebook")}
                                          placeholder={t("Enter Facebook")}
                                          value={props?.data?.facebook ?? null}
                                          onChange={(e) => {
                                                props?.updateState("facebook", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label={t("Twitter")}
                                          placeholder={t("Enter Twitter")}
                                          value={props?.data?.twitter ?? null}
                                          onChange={(e) => {
                                                props?.updateState("twitter", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label={t("Linkedin")}
                                          placeholder={t("Enter Linkedin")}
                                          value={props?.data?.linkedin ?? null}
                                          onChange={(e) => {
                                                props?.updateState("linkedin", e.target.value);
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>
                  <AlertDialog isNormal isnotTitle component={
                        <div className={classes.imageViewerWrapperStyle}>
                              <img src={props?.data?.profileImage} alt='' className={classes.imageViewerImageStyle} />
                        </div>
                  } open={imageViwer} onClose={() => setImageViwer(false)} />
            </div >
      )
}