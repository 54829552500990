import { Box, Stack, Typography } from "@mui/material";
import { DetailsCardStyle } from "./styles";
export const Cards = ({ t, title = "", icon, subTitle = "", subTitle1 = "", assign = false, onClick = () => false }) => {
    const classes = DetailsCardStyle()
    return (
        <Box className={classes.root}>
            <Stack direction="row" justifyContent="space-between">
                <Typography className={classes.title}>{title}</Typography>
                {
                    assign &&
                    <Typography onClick={onClick} className={classes.Reassign}>{t("Re Assign")}</Typography>
                }

            </Stack>

            <Box display="flex" marginTop="-6px" alignItems="center" >
                <Box marginTop="12px">
                    {icon}
                </Box>
                <Box flexGrow={1} marginInlineStart="12px">
                    <Typography className={classes.Name}>{subTitle}</Typography>
                    <Box height="2px" />
                    {subTitle1 !== null &&
                        <Typography noWrap className={classes.description}>{subTitle1}</Typography>
                    }
                </Box>

            </Box>
        </Box>
    )
}