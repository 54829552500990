/* eslint-disable no-unreachable */
import React from "react";
import { SelectBox } from "../../components";
import { Grid, Button, Box } from "@mui/material";
import { ScreensStylesParent } from "../style";
import {  useWindowDimensions } from "../../../../utils";
import { loadOptionsApis } from "../../../../utils/asyncPaginateLoadOptions";

export const AddRole = (props) => {
  // style
  const menuOptionHeight = "200px";
  const classes = ScreensStylesParent();
  const size = useWindowDimensions();

  console.log(props?.addRole?.module?.value  , "lddldl"  )

  return (
    <div className={classes.dialogParent}>
      <div
        style={{
          height: size?.height - 230,
          overflow: "auto",
          padding: "0px 5px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectBox
              isRequired
              placeholder="Select Module"
              label="Module"
              options={props?.moduleOption ?? []}
              onChange={(value) => props?.updateState("module", value)}
              value={props?.addRole?.module}
              isError={props?.addRole?.error?.module?.length > 0}
              errorMessage={props?.addRole?.error?.module}
              menuOptionHeight={menuOptionHeight}
              menuPlacement={"bottom"}
            />
          </Grid>
          {props?.addRole?.module?.label?.length > 0 && (
            
            <>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Role"
                  label="Role"
                  options={[]}
                  onChange={(value) => props?.updateState("role", value)}
                  value={props?.addRole?.role}
                  key={JSON.stringify(props?.addRole?.module)}
                  isError={props?.addRole?.error?.role?.length > 0}
                  errorMessage={props?.addRole?.error?.role}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array , handleLoading) =>
                    loadOptionsApis(
                      "queries/opportunity/client_role/get",
                      {
                          search,
                          module_id:props?.addRole?.module?.value
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                    )
                  }
                  menuOptionHeight={menuOptionHeight}
                  menuPlacement={"bottom"}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Company"
                  label="Company"
                  options={[]}
                  onChange={(value) => props?.updateState("company", value)}
                  isMulti
                  value={props?.addRole?.company}
                  isError={props?.addRole?.error?.company?.length > 0}
                  errorMessage={props?.addRole?.error?.company}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array , handleLoading) =>
                    loadOptionsApis(
                      "queries/company_master/list",
                      {
                        is_dropdown:true
                      },
                      search,
                      array,
                      handleLoading,
                      "company_master",
                      {},
                      null,
                      {
                        all:true,
                        all_label:"Select All",
                        all_value:0
                      }
                    )
                  }
                  menuOptionHeight={menuOptionHeight}
                  menuPlacement={"top"}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Property"
                  label="Property"
                  isMulti
                  options={[]}
                  onChange={(value) => props?.updateState("property", value)}
                  value={props?.addRole?.property}
                  key={JSON.stringify(props?.addRole?.company)}
                  isError={props?.addRole?.error?.property?.length > 0}
                  errorMessage={props?.addRole?.error?.property}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array , handleLoading) =>
                    loadOptionsApis(
                      "queries/property/dropdown",
                      {
                        is_dropdown:true
                      },
                      search,
                      array,
                      handleLoading,
                      "property",
                      {},
                      null,
                      {
                        all:true,
                        all_label:"Select All",
                        all_value:0
                      }
                    )
                  }
                  menuOptionHeight={menuOptionHeight}
                  menuPlacement={"top"}
                />
              </Grid>
            </>
          )}
        </Grid>
        <br />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.addRoleButton}>
            <Box>
              <Button
                className={classes.cancelBtn}
                onClick={props?.addrolePopFunction}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                className={classes.saveBtn}
                onClick={props?.addroleFunction}
              >
                {props?.addRole?.isEdit ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
