import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { AggreementCardInfoStyles } from "./styles";
export const AggreementCardInfo = ({ data = {}, t = () => false }) => {
    const classes = AggreementCardInfoStyles(data)
    return (
        <Box className={classes.cardRoot}>
            {data?.isIcon ? data?.url ?? <Avatar /> :
                data?.url ? <img src={data?.url} alt="" /> : data?.image}
            <Box className={classes.bottomPlace}>
                {data?.isToolTip ?
                    <Tooltip placement="top" title={`${data?.symbol ?? ''} ${data?.title}`}>
                        <Typography noWrap className={classes.title}>{data?.symbol && <span className={classes.currency}>{data?.symbol ?? ''}</span>}{data?.title}</Typography>
                    </Tooltip>
                    :
                    <Typography noWrap className={classes.title}>{data?.symbol && <span className={classes.currency}>{data?.symbol ?? ''}</span>}{data?.title}</Typography>

                }
                <Typography className={classes.subtitle}>{data?.subtitle}</Typography>
            </Box>
        </Box>
    )
}