import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AccordionWithTable } from '../../components';
import { Bold, ExtraBold, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Details } from '../details';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "auto"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

export const UnitTableDetails = props => {

    const classes = useStyles();
    const size = useWindowDimensions();
    //const alert = React.useContext(AlertContext);
    // eslint-disable-next-line
    const [unitDetailsData, setUnitDetailsData] = React.useState([])
    // eslint-disable-next-line
    const [assestData, setAssestData] = React.useState([])
    const getUnitDetailsId = () => {
        return false
    };
    const getAssestsId = () => {
        return false
    };

    React.useEffect(() => {
        getUnitDetailsId();
        getAssestsId();
        // eslint-disable-next-line
    }, []);

    const accordionData = []

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={4} >
                {unitDetailsData.map((item) => (
                    <>
                        <Details data={item} color={true} unitTable={true} assets={assestData?.assets} />
                    </>
                )
                )}
            </Grid>
            <Grid item xs={8} >
                <div
                    style={{
                        height: size.height - 88,
                        overflow: "scroll",
                    }}
                >
                    <Grid conatiner>
                        {accordionData.map((item) => (
                            <>
                                <Grid item xs={12} marginTop={2}>
                                    <AccordionWithTable data={item} />
                                </Grid>
                            </>
                        )
                        )}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    </div>
}