/* eslint-disable array-callback-return */
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Dialog, Grid, IconButton } from "@mui/material";
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import * as React from 'react';
import { TableWithPagination, UseDebounce } from '..';
import { SearchFilter } from "../../components/searchbar";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { concat_string, NetWorkCallMethods, timeZoneConverter } from "../../utils";
import { OtherInfoCard } from "../otherInfoCard";
import { Bold } from '../../utils';
const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize:"1rem"
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    titleBar: {
        borderBottom: "1px solid #E4E8EE"
    }
}))
export const DialogWithTable = (props) => {
    const { t } = (props)
    const classes = useStyles(props);
    const [loading, setLoading] = React.useState(true);
    const [offset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [count, setCount] = React.useState("");
    const [expand, setExpand] = React.useState(false);
    const [search, setSearch] = React.useState("")
    const debounce = UseDebounce()
    const toggleAcordion = () => {
        setExpand(!expand);
    };
    const [list, setList] = React.useState([]);
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTable(offset, limit, search);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTable(0, value, search);
    };

    const getTable = (offset, limit, serachText) => {
        if (expand === true) {
            if (props?.data?.api_endpoint) {
                const payload = {
                    unit_ids: props?.data?.unitId && [props?.data?.unitId],
                    filter: {
                        parent_unit_ids: props?.data?.unitId && [props?.data?.unitId],
                    },
                    owner_customer_type: props?.data?.owner_customer_type,
                    count_type: props?.data?.count_type,
                    agreementId: props?.data?.agreementId && props?.data?.agreementId,
                    offset: offset,
                    limit: limit,
                    search: serachText,
                };
                NetworkCall(
                    `${config.api_url}${props?.data?.api_endpoint}`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).then((response) => {
                        let main = response?.data;
                        const aData = [];

                        if (main?.owner) {
                            main?.owner.map((data) => {
                                const aObj = {}
                                aObj.ownerName = concat_string(
                                    {
                                        first_name: data?.user_profiles?.[0]?.first_name,
                                        last_name: data?.user_profiles?.[0]?.last_name,
                                    },
                                    ["first_name", "last_name"], " "
                                )
                                aObj.ownerphoneno = concat_string(
                                    {
                                        mobile_no_country_code: data?.user_profiles?.[0]?.mobile_no_country_code,
                                        mobile_no: data?.user_profiles?.[0]?.mobile_no,
                                    },
                                    ["mobile_no_country_code", "mobile_no"], " "
                                )
                                aObj.to = timeZoneConverter(data?.to) ? timeZoneConverter(data?.to) : " - "
                                aObj.from = timeZoneConverter(data?.from) ? timeZoneConverter(data?.from) : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.resident) {
                            main?.resident.map((data) => {
                                const aObj = {}
                                aObj.residentName = concat_string(
                                    {
                                        first_name: data?.user_profiles?.[0]?.first_name,
                                        last_name: data?.user_profiles?.[0]?.last_name,
                                    },
                                    ["first_name", "last_name"], " "
                                )
                                aObj.residentphoneno = concat_string(
                                    {
                                        mobile_no_country_code: data?.user_profiles?.[0]?.mobile_no_country_code,
                                        mobile_no: data?.user_profiles?.[0]?.mobile_no,
                                    },
                                    ["mobile_no_country_code", "mobile_no"], " "
                                )
                                aObj.to = timeZoneConverter(data?.to, "DD MMM YY") ? timeZoneConverter(data?.to) : " - "
                                aObj.from = timeZoneConverter(data?.from) ? timeZoneConverter(data?.from) : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_rental_breakup) {
                            main?.unit_rental_breakup.map(data => {
                                const aObj = {}
                                aObj.revenue = data?.revenue_type ?? "-"
                                aObj.component = data?.rental_breakup_master?.description ? data?.rental_breakup_master?.description : " - "
                                aObj.value = data?.value ? data?.value : " - "
                                aObj.currency = data?.currency?.symbol ? data?.currency?.symbol : " - "
                                aObj.chargeable = data?.is_chargeable ? "Yes" : "No"
                                aObj.display = data?.display_percentage ? "true" : "false"
                                aObj.taxGroup = data?.vat_group_master?.group_name ? data?.vat_group_master?.group_name : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_vacancy) {
                            main?.unit_vacancy.map(data => {
                                const aObj = {}
                                aObj.unitNo = data?.unit_no
                                aObj.unitName = data?.name
                                aObj.image = data?.logo
                                aObj.companyName = data?.company_name ?? "-"
                                aObj.reservationNumber = data?.reservation_number ?? "-"
                                aObj.primaryContactNumber = concat_string(
                                    {
                                        mobile_no_country_code: data?.mobile_no_country_code,
                                        mobile_no: data?.mobile_no,
                                    },
                                    ["mobile_no_country_code", "mobile_no"], " "
                                )
                                aObj.primaryContactName = concat_string(
                                    {
                                        first_name: data?.first_name,
                                        last_name: data?.last_name,
                                    },
                                    ["first_name", "last_name"], " "
                                )
                                aObj.agreementNumber = data?.agreement_no ?? "-"
                                aObj.endDate = data?.available_to
                                aObj.startDate = data?.available_from
                                aObj.currentStatus = data?.current_status
                                aData.push(aObj)

                            })
                        }
                        if (main?.unit_amenities_breakup) {
                            main?.unit_amenities_breakup.map(data => {
                                const aObj = {}
                                aObj.name = data?.name ? data?.name : " - "
                                aObj.chargable = data?.is_chargeable ? "Yes" : "No"
                                aData.push(aObj)
                            })
                        }
                        if (main?.data?.unit) {
                            main?.data?.unit.map(data => {
                                const aObj = {}
                                aObj.roomName = data?.name ?? " - "
                                aObj.description = data?.description ?? " - "
                                aObj.roomno = data?.unit_no ?? " - "
                                aObj.roomSttaus = data?.status ?? " - "

                                aData.push(aObj)
                            })
                        }
                        if (main?.request) {
                            main?.request.map((data, index) => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.description
                                aObj.raisedDate = timeZoneConverter(data?.raised_on)
                                aObj.category = data?.request_type
                                aObj.status = data?.request_status
                                aData.push(aObj)

                            })
                        }
                        if (main?.agreement_units) {
                            const unique = [...new Map(main?.agreement_units.map(item => [item.agreement['id'], item])).values()];
                            unique.map((data) => {
                                const aObj = {}
                                aObj.agreementNo = data?.agreement?.agreement_no ?? " - "
                                aObj.validfrom = data?.agreement?.lease_start_date ?? " - "
                                aObj.validto = data?.agreement?.lease_end_date ?? " - "
                                aObj.agreementuser = concat_string(
                                    {
                                        first_name: data?.agreement?.contact?.first_name,
                                        last_name: data?.agreement?.contact?.last_name,
                                    },
                                    ["first_name", "last_name"], " "
                                )
                                aData.push(aObj)
                            })
                        }
                        setList(aData)
                        setLoading(false)
                        setCount(main?.count?.[0]?.count)


                    })
                    .catch((error) => {
                        console.log(error)

                    });
            }

            //api
            if (props?.data?.api) {
                const payload = {
                    tenantId: `${config.tenantId}`,
                    request_units: [props?.data?.unitId],
                    request_type: [
                        "Maintenance",
                        "General"
                    ],
                    offset: offset,
                    limit: limit,
                    search: serachText
                };
                NetworkCall(
                    `${config.api_url}${props?.data?.api}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;

                        const aData = [];
                        if (main) {
                            main.map(data => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.request_name
                                aObj.raisedDate = timeZoneConverter(data?.create_date)
                                aObj.category = data?.request_type
                                aObj.description = data?.description
                                aObj.status = data?.request_status
                                aData.push(aObj)
                            })
                        }
                        setCount(response?.data?.totalCount)
                        setList(aData)
                        setLoading(false)

                    })
                    .catch((error) => {
                        //             alert.setSnack({
                        // ...alert,
                        // open: true,
                        // severity: AlertProps.severity.error,
                        // msg: "Please fill all mandatory field",
                        // vertical: AlertProps.vertical.top,
                        // horizontal: AlertProps.horizontal.center,
                        //  });
                        console.log(error)
                    });

            }

        }
    }
    React.useEffect(() => {
        getTable(offset, limit, search)
        // eslint-disable-next-line
    }, [expand, offset, limit])
    const handleSearch = (e) => {
        setSearch(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getTable(0, 10, e)
    }
    return (
        <div>
            <OtherInfoCard
                data={{
                    name: props?.data?.title,
                    view: `${t("View")} ${props?.data?.title}`,
                    color: props?.data?.color,
                    count: props?.data?.count
                }}
                t={t}
                unit
                onClick={() => {
                    setExpand(true)
                }}
            />
            <Dialog open={expand} maxWidth="lg" fullWidth onClose={toggleAcordion} className={classes.dialog}>
                <Box display="flex" alignItems="center" className={classes.titleBar} p={1}>
                    <Box flexGrow={1}><Typography className={classes.text} >{props?.data?.title}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={toggleAcordion}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                </Box>
                {
                    props?.data?.title !== "Unit Vacancy Period" &&
                    <Box p={1} className={classes.titleBar}>
                        <Grid>
                            <Grid item xs={4}>
                                <SearchFilter handleChange={(e) => handleSearch(e)} value={search} placeholder={`${t("Search")} ${props?.data?.title}`} />
                            </Grid>
                            <Grid xs={8} />
                        </Grid>
                    </Box>
                }

                {loading ? (
                    <div style={{ minHeight: "300px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress color="secondary" />
                    </div>
                ) : (
                    <>

                        {list?.length > 0 ?
                            <div style={{ minHeight: "300px", padding: "12px" }}>
                                <TableWithPagination
                                    heading={list?.length > 0 ? props?.data?.table?.heading : []}
                                    rows={list}
                                    path={list?.length > 0 ? props?.data?.table?.path : []}
                                    showpagination={true}
                                    onClick={() => false}
                                    dataType={props?.data?.table?.dataType}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={count}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 351px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            </div>
                            :
                            <div style={{ minHeight: "300px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography className={classes.text} textAlign="center">
                                    {t("No Records Found")}
                                </Typography>
                            </div>
                        }
                    </>
                )}

            </Dialog>

        </div >
    );
}
